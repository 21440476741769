import axios from "axios";
import {actionTypes} from "../constants/action-types";
import {call, put, takeEvery} from "redux-saga/effects";
import {NotificationManager} from "react-notifications";
import {v4 as uuidv4} from "uuid";
import {poiListWorkerSaga} from "./poiSaga";

export function* saga() {
    yield takeEvery(actionTypes.Places, placeListWorkerSaga)
    yield takeEvery(actionTypes.PlaceById, placeWorkerSaga)
    yield takeEvery(actionTypes.PlaceDelete, placeDeleteWorkerSaga)
    yield takeEvery(actionTypes.PlaceSave, placeSaveWorkerSaga)

}

function* placeListWorkerSaga(action){
    try {
        const results = yield call(fetchPlace, action.payload);
        const more = action.payload.start > 0
        yield put({type: actionTypes.PlacesSuccess, payload: {...results.data, more: more}})
    } catch(e){
        yield put({type: actionTypes.PlacesError})
    }
}
function* placeWorkerSaga(action){
    try {
        const {id} = action.payload
        const result = yield call(fetchPlaceById, id);

        if(Array.isArray(result.data) && !result.data.length){
            console.log("ERRORE: ", result.data)
            action.history.push(`/itemnotfound/luoghi/${id}`);
        } else {
            console.log("NORMALE: ", result.data)
            yield put({type: actionTypes.PlaceSuccess, payload: result.data})
        }
    } catch(e){
        yield put({type: actionTypes.PlaceError})
    }
}

function* placeDeleteWorkerSaga(action){
    try {
        const {id} = action.payload
        const results = yield call(deletePlace, id);
        //payload: {start, rows}
        yield put({type: actionTypes.Places, payload: {start: 0, rows: 10}})
    } catch(e){
        yield put({type: actionTypes.PlaceError})
    }
}

function* placeSaveWorkerSaga(action) {
    try {
        let place =  action.payload;
        if (!place.identifier || place.identifier === "") {
            const identifier = uuidv4()
            const objectGroupId = identifier.substring(0, 4)
            const filename = identifier + ".xml"
            place = {
                ...place,
                identifier: identifier,
                objectGroupId: objectGroupId,
                fileName: filename
            }
        }

        const result = yield call(savePlace, place);
        NotificationManager.info("Luogo salvato correttamente.", "Save Luogo", 5000, null, null, "filled")
        action.history.push('/luoghi/list');
    } catch (e) {
        const msg = `Impossibile salvare il luogo.`
        NotificationManager.error(msg, "Errore Save Luogo", 5000, null, null, "filled")
        yield put({type: actionTypes.PlaceError});
    }
}

function fetchPlace({start, rows}){
    const params = {start: start, rows: rows}
    return axios.get(process.env.REACT_APP_SERVICE_PLACE_LIST_URL, {
        params: params
    });
}

function deletePlace(id){
    console.log("deletePlace(id): " + id)
    return axios.get(process.env.REACT_APP_SERVICE_PLACE_DELETE + `?identifier=${id}`);
}

const fetchPlaceById = (id) => {
    return axios.get(process.env.REACT_APP_SERVICE_PLACE_BY_ID_URL + id + '.json');
}

const savePlace = async (data) => {
    return axios.post(process.env.REACT_APP_SERVICE_PLACE_SAVE_URL, data);
}
