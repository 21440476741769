import React, {useEffect, useState} from "react";
import {Button, Tab, Tabs} from "react-bootstrap";
import {useFormikContext} from "formik";
import GenericDate from "./GenericDate";
import AC from "./AC";
import {useHeaderDialog} from "../../../_metronic/layout/_core/MetronicHeaderDialog";
import CM from "./CM";
import LM from "./LM";


const PoiDetail = (props) => {


    const [loading, setLoading] = useState(false);
    const [poi, setPoi] = useState(null);

    const headerDialog = useHeaderDialog();
    const formik = useFormikContext();

    let initialValues = {};

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    // props.onSave(values);
    const handleSave = (values) => {
       formik.handleSubmit();
    }

    const handleCancel = () => {
        props.onCancel();
    }

    const handleBack = () => {
        const actions = [
            {action: {handleCancel}, icon: 'cancel', label: 'Cancel', variant: 'secondary', className: 'mr-2'},
            {action: {handleSave}, icon: 'save', label: 'Salva Poi'},
        ]
        headerDialog.setActions(actions)
    }

    useEffect(() => {

        const actions = [
            {action: {handleCancel}, icon: 'cancel', label: 'Cancel', variant: 'secondary', className: 'mr-2'},
            {action: {handleSave}, icon: 'save', label: 'Salva Poi'},
        ]
        headerDialog.setActions(actions)

    }, [])

    useEffect(() => {
        setPoi(props.data);

    }, [props.data])


    return (
        <>
            {poi && (
                <>
                    <Tabs defaultActiveKey="datigenerali" id="uncontrolled-tab-example"
                          className={headerDialog.show ? 'border-0' : ''}>
                        {!headerDialog.show && (
                            <Tab eventKey="datigenerali" title="Dati Generali" className="py-5">
                                <>
                                    <GenericDate/>
                                </>
                            </Tab>
                        )}
                        <Tab eventKey="ac" title={headerDialog && headerDialog.show == 'AC' ? 'Dettaglio AC' : 'AC'}
                             className="py-5" tabClassName={headerDialog && headerDialog.show ? 'd-none' : ''}>
                            <AC onBack={handleBack}/>
                        </Tab>
                        <Tab eventKey="cm" title={headerDialog && headerDialog.show == 'CM' ? 'Dettaglio CM' : 'CM'}
                             className="py-5" tabClassName={headerDialog && headerDialog.show ? 'd-none' : ''}>
                            <CM onBack={handleBack}/>
                        </Tab>
                        <Tab eventKey="lm" title={headerDialog && headerDialog.show == 'LM' ? 'Dettaglio LM' : 'LM'}
                             className="py-5" tabClassName={headerDialog && headerDialog.show ? 'd-none' : ''}>
                            <LM onBack={handleBack}/>

                        </Tab>

                    </Tabs>

                </>
            )}
        </>
    )

}


export default PoiDetail;