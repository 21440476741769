import React, {useEffect} from "react";
import * as taxonomy from "../../../redux/taxonomy/taxonomy.action";
import {useFormikContext} from "formik";
import {Col, Form, InputGroup} from "react-bootstrap";
import connect from "react-redux/es/connect/connect";

function GenericDate(props) {
    // const { values, submitForm } = useFormikContext();


    const formik = useFormikContext();
    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";
    };
    useEffect(() => {


        props.taxonomyList('tag');
    }, [])


    return (
        <>
            <Form.Group controlId="formTitle">
                <Form.Label>Title</Form.Label>
                <Form.Control type="text" placeholder="Title" {...formik.getFieldProps("title")} className={`${getInputClasses("title")}`}/>
                {formik.touched.title && formik.errors.title ? (
                    <div className="fv-plugins-message-container">
                        <div className="fv-help-block text-danger">{formik.errors.title}</div>
                    </div>
                ) : null}
            </Form.Group>
            <Form.Group controlId="formDescription">
                <Form.Label>Description</Form.Label>
                <Form.Control as="textarea" rows={3} {...formik.getFieldProps("description")}  />
            </Form.Group>
            <Form.Group controlId="formInformation">
                <Form.Label>Information</Form.Label>
                <Form.Control type="text" placeholder="Information"  {...formik.getFieldProps("informazioni")}  />
            </Form.Group>
            <Form.Group controlId="formLat">
                <Form.Label>Address</Form.Label>
                <Form.Control placeholder="Address" {...formik.getFieldProps("indirizzo")}/>
            </Form.Group>
            <Form.Row>
                <Form.Group as={Col} controlId="formLat">
                    <Form.Label>Latitude</Form.Label>
                    <Form.Control placeholder="Latitude" {...formik.getFieldProps("lat")}
                                  className={`${getInputClasses("lat")}`}/>
                    {formik.touched.lat && formik.errors.lat ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block text-danger">{formik.errors.lat}</div>
                        </div>
                    ) : null}
                </Form.Group>
                <Form.Group as={Col} controlId="formLon">
                    <Form.Label>Longitude</Form.Label>
                    <Form.Control placeholder="Longitude" {...formik.getFieldProps("lon")}
                                  className={`${getInputClasses("lon")}`}/>
                    {formik.touched.lon && formik.errors.lon ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block text-danger">{formik.errors.lon}</div>
                        </div>
                    ) : null}
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Form.Group as={Col} controlId="formHours">
                    <Form.Label>Hours</Form.Label>
                    <Form.Control placeholder="Hours" {...formik.getFieldProps("orari")}/>
                </Form.Group>
                <Form.Group as={Col} controlId="formPhone">
                    <Form.Label>Phone</Form.Label>
                    <Form.Control placeholder="Phone" {...formik.getFieldProps("telefono")}
                                  className={`${getInputClasses("telefono")}`}/>
                    {formik.touched.telefono && formik.errors.telefono ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block text-danger">{formik.errors.telefono}</div>
                        </div>
                    ) : null}
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Form.Group as={Col} controlId="formEmail">
                    <Form.Label>Email</Form.Label>
                    <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                            <InputGroup.Text id="basic-addon1">@</InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control placeholder="Email" {...formik.getFieldProps("email")}
                                      className={`${getInputClasses("email")}`}/>
                        {formik.touched.email && formik.errors.email ? (
                            <div className="fv-plugins-message-container">
                                <div className="fv-help-block text-danger">{formik.errors.email}</div>
                            </div>
                        ) : null}
                    </InputGroup>
                </Form.Group>
                <Form.Group as={Col} controlId="formWebsite">
                    <Form.Label>Web Site</Form.Label>
                    <Form.Control placeholder="Web Site" {...formik.getFieldProps("sito_web")}
                                  className={`${getInputClasses("sito_web")}`}/>
                    {formik.touched.sito_web && formik.errors.sito_web ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block text-danger">{formik.errors.sito_web}</div>
                        </div>
                    ) : null}
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Form.Group as={Col} controlId="formTagType">
                    <Form.Label>Tag Type</Form.Label>
                    <Form.Control as="select" {...formik.getFieldProps("tag_type")}>
                        <option key="0" value={''}>Seleziona un valore</option>
                        {props.tags.items.map((tag) => (
                                <option key={tag.key} value={tag.key}>{tag.value}</option>

                            )
                        )}
                    </Form.Control>
                </Form.Group>
                <Form.Group as={Col} controlId="formImportance">
                    <Form.Label>Importance</Form.Label>
                    <Form.Control
                            placeholder="importance" {...formik.getFieldProps("importance")}
                            className={`${getInputClasses("importance")}`}/>
                    {formik.touched.importance && formik.errors.importance ? (
                    <div className="fv-plugins-message-container">
                        <div className="fv-help-block text-danger">{formik.errors.importance}</div>
                    </div>
                    ) : null}
                </Form.Group>
            </Form.Row>


        </>

    );
}



const mapStateToProps = ({taxonomy}) => {
    return {
        tags: taxonomy.tagList
    };
};
export default connect(mapStateToProps, taxonomy.actions)(GenericDate);