import cookie from 'react-cookies'
import Cookies from 'js-cookie';
import {v4 as uuidv4} from "uuid";

export default function setupAxios(axios, store) {

    axios.defaults.withCredentials = true
    // const gatewayUrl = process.env.NODE_ENV === "development" ? 'http://localhost:3001' : '';
//  axios.defaults.baseURL = gatewayUrl;



   /* const res = axios('http://192.168.36.104:8090/api/config');
    console.log(res.data.csrf_token);
*/

    const errorHandler = (error) => {

        const status = error.response ? error.response.status : null
        console.log("Status ", status);
        if (status === 403) {
            console.log("Error QUI", cookie.loadAll());
        }
        return Promise.reject({...error})
    }

    const successHandler = (response) => {
        return response
    };

    axios.interceptors.response.use(

        response => successHandler(response),
        error => errorHandler(error)

    )

    axios.interceptors.request.use(
        async (request) => {

            try {

                let xsrf = Cookies.get(request.xsrfCookieName);
                Cookies.set('crafterSite',  process.env.REACT_APP_SITE_NAME);
                if (xsrf) {
                    request.headers[request.xsrfHeaderName] = xsrf;

                } else {
                    xsrf = request.headers[request.xsrfHeaderName];
                    if (xsrf) {
                        Cookies.set(request.xsrfCookieName, xsrf);
                    } else {
                        //TODO - Da approfondire gestione xsrf-token
                        console.log("CASO NO XSRF-Token")
                        const uuid = uuidv4();
                        Cookies.set(request.xsrfCookieName, uuid);
                        request.headers[request.xsrfHeaderName] = xsrf;
                    }
                }

                return request;
            } catch (err) {
                throw new Error(`axios# Problem with request during pre-flight phase: ${err}.`);
            }
        },
        config => {
            const { auth: {authToken}} = store.getState();

            if (authToken) {
                config.headers.Authorization = `Bearer ${authToken}`;
            }
            /*config.headers.post['header1'] = 'value';
            config.xsrfCookieName = 'XSRF-TOKEN';
            config.xsrfHeaderName = 'X-XSRF-TOKEN';
            config.withCredentials = true;*/
            return config;
        },
        err => Promise.reject(err)
    );
}
