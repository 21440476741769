import React, {Suspense} from "react";
import {Redirect, Switch} from "react-router-dom";
import {ContentRoute, LayoutSplashScreen} from "../_metronic/layout";
import {DashboardPage} from "./pages/DashboardPage";
import CategoriaPage from "./pages/tassonomie/CategoriaPage";
import TagPage from "./pages/tassonomie/TagPage";
import SensorPage from "./pages/tassonomie/SensorPage";
import ContentPage from "./pages/tassonomie/ContentPage";
import AgeTargetPage from "./pages/tassonomie/AgeTargetPage";
import PoiPage from "./pages/pois/PoiPage";
import PoiDetailPage from "./pages/pois/PoiDetailPage";
import TypePage from "./pages/tassonomie/TypePage";
import PlacePage from "./pages/places/PlacePage";
import PlaceDetailPage from "./pages/places/PlaceDetailPage";
import InterestsPage from "./pages/tassonomie/InterestsPage";
import StylesPage from "./pages/tassonomie/StylesPage";
import TypologyPage from "./pages/tassonomie/TypologyPage";
import {ItemNotFoundPage} from "./pages/ItemNotFoundPage";

/*
const UserProfilepage = lazy(() =>
  import("./modules/UserProfile/UserProfilePage")
);
*/
export default function BasePage() {
    // useEffect(() => {
    //   console.log('Base page');
    // }, []) // [] - is required if you need only one call
    // https://reactjs.org/docs/hooks-reference.html#useeffect

    return (
        <Suspense fallback={<LayoutSplashScreen/>}>
            <Switch>
                {
                    /* Redirect from root URL to /dashboard. */
                    <Redirect exact from="/" to="/dashboard"/>
                }
                <ContentRoute path="/dashboard" component={DashboardPage}/>
                <ContentRoute path="/tassonomia/categorie" component={CategoriaPage}/>
                <ContentRoute path="/tassonomia/tags" component={TagPage}/>
                <ContentRoute path="/tassonomia/agetarget" component={AgeTargetPage}/>
                <ContentRoute path="/tassonomia/type" component={TypePage}/>
                <ContentRoute path="/tassonomia/compatiblesensors" component={SensorPage}/>
                <ContentRoute path="/tassonomia/compatiblecontents" component={ContentPage}/>
                <ContentRoute path="/tassonomia/interests" component={InterestsPage}/>
                <ContentRoute path="/tassonomia/styles" component={StylesPage}/>
                <ContentRoute path="/tassonomia/typologies" component={TypologyPage}/>
                <ContentRoute path="/poi/list" component={PoiPage}/>
                <ContentRoute path="/poi/:id" component={PoiDetailPage}/>
                <ContentRoute path="/luoghi/list" component={PlacePage}/>
                <ContentRoute path="/luoghi/:id" component={PlaceDetailPage}/>
                <ContentRoute path="/itemnotfound/poi/:id" component={ItemNotFoundPage}/>
                <ContentRoute path="/itemnotfound/luoghi/:id" component={ItemNotFoundPage}/>
                {/* <Route path="/user-profile" component={UserProfilepage} /> */}
                <Redirect to="/error/error-v1"/>
            </Switch>
        </Suspense>
    );
}
