import GenericTable from "../common/datadisplay/GenericTable";
import ConfirmDialog from "../common/ConfirmDialog";
import React, {useCallback, useState} from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import {Button} from "react-bootstrap";


const PlaceList = (props) => {

    const headers = [
        {
            dataField: 'type',
            text: 'Type'
        },
        {
            dataField: 'title',
            text: 'Title'
        },
        {
            dataField: 'description',
            text: 'Description'
        },
        {
            dataField: 'df1',
            isDummyField: true,
            text: 'Action',
            headerClasses: 'text-right',
            classes: 'text-right',
            formatter: (cellContent, row) => {
                return (
                    <>
                        <Button variant="link" size="sm"
                                onClick={(e) => handleDetailClick(e, row)}>
                            <i className="fas fa-eye"></i>
                        </Button>
                        <Button variant="link" size="sm"
                                onClick={(e) => handleDelete(e, row)}>
                            <i className="fas fa-trash"></i>
                        </Button>
                    </>
                )
            }
        }
    ]

    const [placeDelete, setPlaceDelete] = useState(null);
    const [modalConfirm, setModalConfirm] = useState(null);

    const handleDelete = useCallback((event, data) => {
        setModalConfirm(true)
        setPlaceDelete(data);
    })

    const toggleConfirm = () => {
        setModalConfirm(!modalConfirm);
    }

    const deleteItem = useCallback(() => {
        props.onDelete(placeDelete.identifier);
        setPlaceDelete(null);
        setModalConfirm(false)
    })

    const handleDetailClick = (e, data) => {
        props.handleDetail(data.identifier)
    }
    return (
        <>
            <div className="row">
                {/*<ConfirmDialog modal={this.state.modalConfirm} onToggle={this.toggleConfirm}
                               action={() => this.deleteItem()}
                               title={this.props.dialogTitle} content={this.props.dialogContent}/>*/}
                <div className="col-lg-12 col-xxl-12">
                    <div className="card card-custom gutter-b">
                        <div className="card-header">
                            <div className="card-title">
                                <h3 className="card-label font-weight-bolder text-dark">Lista Luoghi</h3>
                            </div>
                        </div>
                        <div className="card-body">
                            {props.values && (
                                <>

                                    <BootstrapTable
                                        keyField='identifier'
                                        data={props.values.items}
                                        columns={headers}
                                        bootstrap4
                                        striped
                                        hover={true}
                                        bordered={false}
                                        pagination={paginationFactory()}
                                    />


                                    <ConfirmDialog modal={modalConfirm} onToggle={toggleConfirm}
                                                   action={() => deleteItem()}
                                                   title="Cancella l'item luogo"
                                                   content="Sei sicuro di voler cancellare l'item luogo?"/>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PlaceList;