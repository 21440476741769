import {actionTypes} from "../../constants/action-types";
import moment from "moment";

const APP_LOCAL_DATETIME_FORMAT = 'YYYY-MM-DDThh:mm:ss.SSS[Z]';
const initialState = {
    place: {
        age_target: '',
        compatible_contents: [],
        compatible_sensors: [],
        description: '',
        language: '',
        created_date:  moment(new Date()).format(APP_LOCAL_DATETIME_FORMAT),
        last_date:  moment(new Date()).format(APP_LOCAL_DATETIME_FORMAT),
        paths: [],
        pois: [],
        title: '',
        type: '',
        version: ''
    },
    placeList: { total: 0, items: []}
}

export  const reducer =
    (state = initialState, action) => {
        switch (action.type) {
            case actionTypes.PlaceInitialize : {
                return {...state, place: initialState.place}
            }
            case actionTypes.PlacesSuccess: {
                const list = action.payload.more ? [...state.placeList.items, ...action.payload.items] : action.payload.items
                return {...state, placeList: action.payload }
            }

            case actionTypes.PlaceSuccess: {
                return {...state, place: action.payload }
            }
            default:
                break;
        }

        return state;
    }