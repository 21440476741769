import React, {useCallback, useEffect, useState} from "react";
import {useFormikContext} from "formik";
import BootstrapTable from "react-bootstrap-table-next";
import {Button} from "react-bootstrap";
import ConfirmDialog from "../common/ConfirmDialog";

function PathGroup(props) {
    const formik = useFormikContext();

    const handleDeleteClick = (e, value) => {
        console.log('Delete', value)
        props.onDelete(value)
    }

    const handleDetailClick = (e, value) => {
        console.log("Formik.values: ", formik.values.pois)
        props.onDetail(value)
    }

    const handleNewPath = () => {
        props.onCreate();
    }

    const columnsExpander = [
        {
            dataField: 'title',
            text: 'Main Title',
        },
        {
            dataField: 'mime_type',
            text: 'Mime Type',
        }

    ]
    const columnsStageExpander = [
        {
            dataField: 'type',
            text: 'Type'
        },
        {
            dataField: 'title',
            text: 'Title'
        }

    ]
    const columns = [
        {
            dataField: 'color',
            text: '',
            formatter: (cell, row, rowIndex, extraData) => {
                const color = `#${row.color}`
                return (<i className="fas fa-circle" style={{color: color}}/>)
            },
        },
        {
            dataField: 'title',
            text: 'Title'
        }, {
            dataField: 'age_target',
            text: 'Age Target'
        }, {
            dataField: 'duration',
            text: 'Duration'
        },
        {
            dataField: 'df1',
            isDummyField: true,
            text: 'Action',
            classes: 'text-right',
            headerFormatter: (column, colIndex) => {
                return (

                    <Button className="float-right"
                            size="sm"
                            variant="outline-light"
                            onClick={() => handleNewPath()}>
                        <i className="fas fa-plus-square"/> Nuovo Path
                    </Button>

                )
            },
/*
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    console.log(e);
                    console.log(column);
                    console.log(columnIndex);
                    console.log(row);
                    console.log(rowIndex);
                    handleDetailClick(e, row)
                }            },
*/
            formatter: (cellContent, row, rowIndex, extraData) => (
                <>
                        <Button variant="link" size="sm" onClick={(e) => handleDetailClick(e, row)} >
                            <i className="fas fa-eye"></i>
                        </Button>
                        <Button variant="link" size="sm" onClick={(e) => handleDeleteClick(e, row)} >
                            <i className="fas fa-trash"></i>
                        </Button>
                </>
                ),
            formatExtraData: formik
        }
    ];

    const expandRow = {
        className: 'expanding-table',

        renderer: row => (
            <>

                <BootstrapTable
                    key="Main"
                    keyField='identifier'
                    data={row.main}
                    columns={columnsExpander}
                    bootstrap4
                    striped={false}
                    hover={false}
                    bordered={true}
                    wrapperClasses="inner"
                    classes="no-hover, table-no-stripped border-info"
                />

                <div className="separator separator-dashed my-8 border-info"></div>
                <BootstrapTable
                    key="Stage"
                    keyField='content_id'
                    data={row.stages}
                    columns={columnsStageExpander}
                    bootstrap4
                    striped={false}
                    hover={false}
                    bordered={true}
                    wrapperClasses="inner"
                    classes="no-hover, table-no-stripped border-info"
                />
            </>
        ),
        showExpandColumn: true,
        onExpand: (row, isExpand, rowIndex, e) => {
          console.log('isExpand', isExpand)
        },
       /* onExpand: (row, isExpand, rowIndex, e) => {
            setRowExpanded(isExpand ? rowIndex : null)
        },*/
        expandHeaderColumnRenderer: ({isAnyExpands}) => {
            if (isAnyExpands) {
                return <i className="fas fa-minus-square"/>;
            }
            return <i className="fas fa-plus-square"/>;
        },
        expandColumnRenderer: ({expanded}) => {
            if (expanded) {
                return (
                    <i className="far fa-caret-square-right"/>
                );
            }
            return (
                <i className="far fa-caret-square-down"/>
            );
        }
    };



    useEffect(() => {

    }, [formik.values])

    return (
        <>
            <h5 className="mt-5">Paths</h5>
            <div className="separator separator-dashed "></div>
            <BootstrapTable
                keyField='identifier'
                data={formik.values.paths}
                columns={columns}
                bootstrap4
                striped
                hover={true}
                bordered={false}
                expandRow={expandRow}
            />


        </>
    );
}

export default PathGroup;