import {actionTypes} from "../../constants/action-types";
import moment from "moment";

const APP_LOCAL_DATETIME_FORMAT = 'YYYY-MM-DDThh:mm:ss.SSS[Z]';
const initialState = {
    poi: {
        title: '',
        fileName: '',
        description: '',
        telefono: '',
        importance: '',
        lat: '',
        lon: '',
        informazioni: '',
        indirizzo: '',
        orari: '',
        sito_web: '',
        email: '',
        tag_type: '',
        created_date: moment(new Date()).format(APP_LOCAL_DATETIME_FORMAT),
        last_date: moment(new Date()).format(APP_LOCAL_DATETIME_FORMAT),
        contents: {
            AC: [],
            CM: [],
            LM: []
        },
    },
    poisList: {total: 0, items: []},
    poiCount: {total: 0}
};

export const reducer =
    (state = initialState, action) => {
        switch (action.type) {
            case actionTypes.PoiInitialize : {
                return {...state, poi: initialState.poi}
            }
            case actionTypes.PoisSuccess : {

                const list = action.payload.more ? [...state.poisList.items, ...action.payload.items] : action.payload.items
                return {
                    ...state,
                    poisList: {
                        ...state.poisList,
                        total: action.payload.total,
                        items: list}}
            }
            case actionTypes.PoiSuccess: {

                return {...state, poi: action.payload}
            }
            case actionTypes.PoiCountSuccess: {

                return {...state, poiCount: action.payload}
            }
            default:
                break;
        }

        return state;
    }
