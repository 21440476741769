import {combineReducers} from "redux";

import * as auth from "./auth/auth.reducer";
import * as taxonomy from "./taxonomy/taxonomy.reducer";
import * as poi from "./poi/poi.reducer";
import * as place from "./place/place.reducer";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  taxonomy: taxonomy.reducer,
  poi: poi.reducer,
  place: place.reducer
});

