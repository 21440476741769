import React, {useEffect, useState} from "react";
import filterFactory, {textFilter} from "react-bootstrap-table2-filter";
import BootstrapTable from "react-bootstrap-table-next";
import {useFormikContext} from "formik";
import connect from "react-redux/es/connect/connect";
import paginationFactory from "react-bootstrap-table2-paginator";
import {Alert, Button, Modal} from "react-bootstrap";
import {NotificationManager} from "react-notifications";

function PoiGroup(props) {
    const formik = useFormikContext();
    const [alert, setAlert] = useState({message: '', show: false})
    //   const [page, setPage] = useState(0);
    //  const [messageLoadMore, setMessageLoadMore] = useState(null);
    //  const size = 5;

    const columnsPoi = [
        {
            dataField: 'title',
            text: 'Title',
            filter: textFilter({
                    onFilter: filterByTitle,
                    className: 'mx-2',
                    placeholder: 'Ricerca Titolo'
                }
            )
        },
        {
            dataField: 'ac',
            text: 'AC',
        },
        {
            dataField: 'cm',
            text: 'CM',
        },
        {
            dataField: 'lm',
            text: 'LM',
        }
    ];

    const selectPoiRow = {

        mode: 'checkbox',
        clickToSelect: true,
        selected: formik.values.pois.map(item => item.identifier),
        onSelect: (row, isSelect) => handleOnPoiSelect(row, isSelect),
        onSelectAll: (isSelect, rows) => handleOnPoiSelectAll(isSelect, rows)

    }

    const filterByTitle = (filterVal, data) => {
        return data.filter(p => p.title.includes(filterVal));
        // data;
    }

    const handleOnSelectAllPages = (isSelect) => {
        if (isSelect) {
            formik.setFieldValue('pois',[...props.pois.items.map(row => ({
                identifier: row.identifier,
                title: row.title
            }))])
        } else {
            const list = props.pois.items.filter(item => formik.values.pois.some(r => r.identifier === item.identifier)).map(p => p.contents).flat();
            const paths = formik.values.paths.filter(path => path.stages.find(stage => list.find(l => stage.content_id === l.identifier)))
            if(paths.length > 0){
                NotificationManager.error(`Impossibile deselezionare tutto. Il paths "${paths[0].title}" contiene un AC/CM/LM`, "Deseleziona Poi", 5000, null, null, "filled")
                return formik.values.pois.map(r => r.identifier);
            }else {
                formik.setFieldValue('pois', [])
            }
        }
        setAlert({...alert, show: false})
    }


    const handleOnPoiSelect = (row, isSelect) => {

        if (isSelect) {
            formik.setFieldValue('pois', [...formik.values.pois, {identifier: row.identifier, title: row.title}])

        } else {
            const list = props.pois.items.filter(item => row.identifier === item.identifier).map(p => p.contents).flat();
            const paths = formik.values.paths.filter(path => path.stages.find(stage => list.find(l => stage.content_id === l.identifier)))
            console.log("PATHS: ", paths)
            if(paths.length > 0){
                NotificationManager.error(`Impossibile deselezionare il poi. Il paths "${paths[0].title}" contiene un AC/CM/LM`, "Deseleziona Poi", 5000, null, null, "filled")
                return false;
            }else {
                formik.setFieldValue('pois', formik.values.pois.filter(x => x.identifier !== row.identifier))

            }

        }
    }

    const handleOnPoiSelectAll = (isSelect, rows) => {
        //const ids = rows.map(r => r.identifier);
        if (isSelect) {
            const listSelected = [...formik.values.pois, ...rows.map(row => ({
                identifier: row.identifier,
                title: row.title
            }))]
            formik.setFieldValue('pois', listSelected)
            console.log(`Items selezionati ${listSelected.length} items totali ${props.pois.items.length}`)
            setAlert({isSelect: isSelect, number: rows.length, show: listSelected.length < props.pois.items.length})
        } else {

            let listItems =  formik.values.pois.filter(l => !rows.some(r => r.identifier === l.identifier))
            const list = props.pois.items.filter(item => rows.some(r => r.identifier === item.identifier)).map(p => p.contents).flat();
            const paths = formik.values.paths.filter(path => path.stages.find(stage => list.find(l => stage.content_id === l.identifier)))
            if(paths.length > 0){
                NotificationManager.error(`Impossibile deselezionare tutto. Il paths "${paths[0].title}" contiene un AC/CM/LM`, "Deseleziona Poi", 5000, null, null, "filled")
                return rows.map(r => r.identifier);
            }else {
                setAlert({isSelect: isSelect, number: rows.length, show: listItems.length > 0})
                formik.setFieldValue('pois', listItems)
            }

        }

    }

    useEffect(() => {
    }, [formik.values.pois]);

    /*const loadMore = () => {
        const start = (page + 1) * size;
        props.poiList(start, size);
        setPage(page + 1);
    }
*/


    /* useEffect(() => {
         const filteredArray = formik.values.pois.filter(a => props.pois.items.some(b => a.identifier === b.identifier));
        // setMessageLoadMore(filteredArray.length !== formik.values.pois.length ? "Sono presenti poi selezionati non visualizzati" : null)
     }, [ formik.values.pois])
 */
    return (

        <>
            <h5 className="mt-5">Poi</h5>
            <div className="separator separator-dashed "></div>
            {/*
            <Alert show={messageLoadMore ? true : false} variant="warning" className="my-4">
                <Alert.Heading><i className="fas fa-exclamation-triangle text-light"/> Attenzione</Alert.Heading>
                {messageLoadMore}
            </Alert>
            */}
            <Alert variant="warning" show={alert.show} onClose={() => setAlert({...alert, show: false})} dismissible>
                Hai {alert.isSelect ? 'selezionato' : 'deselezionato'} {alert.number} elementi.
                <Alert.Link as={Button} variant="link"
                            onClick={() => handleOnSelectAllPages(alert.isSelect)}>{alert.isSelect ? 'Seleziona' : 'Deseleziona'} tutti
                    i {props.pois && props.pois.items ? props.pois.items.length : 0} elementi.</Alert.Link>
            </Alert>
            {props.pois && (
                <BootstrapTable
                    keyField='identifier'
                    data={props.pois.items}
                    columns={columnsPoi}
                    bootstrap4
                    striped
                    hover={true}
                    bordered={false}
                    selectRow={selectPoiRow}
                    filter={filterFactory()}
                    pagination={paginationFactory()}
                />
            )}

        </>
    );
}

const mapStateToProps = ({poi}) => {
    return {
        pois: poi.poisList

    };
}

export default connect(mapStateToProps)(PoiGroup);