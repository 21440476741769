import connect from "react-redux/es/connect/connect";

import * as taxonomy from "../../../redux/taxonomy/taxonomy.action";
import BootstrapTable from "react-bootstrap-table-next";
import React, {useEffect} from "react";
import {useFormikContext} from "formik";


function CompatibleGroup(props) {
    const formik = useFormikContext();

    const columns = [{
        dataField: 'key',
        text: 'Key',
    }, {
        dataField: 'value',
        text: 'Value',

    }];

    const selectRow = (field, key = 'key', selected = formik.values[field]) => {
        return {
            mode: 'checkbox',
            clickToSelect: true,
            selected: selected,
            onSelect: (row, isSelect) => handleOnSelect(field, key, selected, row, isSelect),
            onSelectAll: (isSelect, rows) => handleOnSelectAll(field, key, isSelect, rows)
        }
    };
    const handleOnSelect = (field, key, selected, row, isSelect) => {
        console.log('handleOnSelect', row)
        if (isSelect) {
            formik.setFieldValue(field, [...selected, row[key]])

        } else {
            formik.setFieldValue(field, selected.filter(x => x !== row[key]))

        }
    }

    const handleOnSelectAll = (field, key, isSelect, rows) => {

        const ids = rows.map(r => r[key]);
        if (isSelect) {
            formik.setFieldValue(field, ids)
        } else {
            formik.setFieldValue(field, [])

        }
    }

    useEffect(() => {
        props.taxonomyList('compatiblesensors');
        props.taxonomyList('compatiblecontents');
    }, [])

    return (
        <div className="row">
            <div className="col-sm">
                <h5 className="mt-5">Compatible Sensors</h5>
                <div className="separator separator-dashed"></div>
                <BootstrapTable
                    keyField='key'
                    data={props.compatiblesensors.items}
                    columns={columns}
                    bootstrap4
                    striped
                    hover={true}
                    bordered={false}
                    selectRow={selectRow('compatible_sensors')}
                />
            </div>
            <div className="col-sm">
                <h5 className="mt-5">Compatible Contents</h5>
                <div className="separator separator-dashed "></div>
                <BootstrapTable
                    keyField='key'
                    data={props.compatiblecontents.items}
                    columns={columns}
                    bootstrap4
                    striped
                    hover={true}
                    bordered={false}
                    selectRow={selectRow('compatible_contents')}
                />
            </div>
        </div>
    )
}

const mapStateToProps = ({taxonomy}) => {
    return {
        compatiblesensors: taxonomy.compatiblesensorsList,
        compatiblecontents: taxonomy.compatiblecontentsList,

    };
}
export default connect(mapStateToProps, taxonomy.actions)(CompatibleGroup);