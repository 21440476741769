import React, {useEffect, useState} from "react";
import {Alert, Button, Modal} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from 'react-bootstrap-table2-paginator';


const StageDialog = (props) => {

    const [selected, setSelected] = useState([])
    const [alert, setAlert] = useState({message: '', show: false})

    const columnStages = [
         {
            dataField: 'type',
            text: 'Type'
        },
        {
            dataField: 'title',
            text: 'Title'
        }

    ];

    const selectRow = {
        mode: 'checkbox',
        clickToSelect: true,
        selected: selected.map(s => s.identifier),
        onSelect: (row, isSelect) => handleOnSelect(row, isSelect),
        onSelectAll: (isSelect, rows) => handleOnSelectAll(isSelect, rows)
    };

    const handleOnSelect = (row, isSelect) => {

        let listStageSelected = selected;

        if (isSelect) {
            listStageSelected.push({
                content_id: row.identifier,
                identifier: row.identifier,
                type: row.type,
                title: row.title
            })
        } else {
            listStageSelected = listStageSelected.filter(l => l.identifier != row.identifier)
        }

        setSelected([...listStageSelected])
    }

    const handleOnSelectAll = (isSelect, rows) => {

        let listStageSelected = selected;
        if (isSelect) {
            listStageSelected = [...listStageSelected, ...rows.map(row => ({
                content_id: row.identifier,
                identifier: row.identifier,
                type: row.type,
                title: row.title
            }))]
            setAlert({isSelect: isSelect, number: rows.length, show: listStageSelected.length < props.value.length})
        } else {
            listStageSelected = listStageSelected.filter(l => !rows.some(r => r.identifier === l.identifier))
            setAlert({isSelect: isSelect, number: rows.length, show: listStageSelected.length > 0})

        }

        setSelected([...listStageSelected]);
    }

    const handleOnSelectAllPages = (isSelect) => {
        if (isSelect) {
            setSelected([...props.value.map(row => ({
                content_id: row.identifier,
                identifier: row.identifier,
                type: row.type,
                title: row.title
            }))])
        } else {
            setSelected([])
        }
        setAlert({...alert, show: false})
    }


    const handleOnSubmit = () => {
        props.onSubmit(selected);

    }


    useEffect(() => {
        setSelected(props.selected)
    }, [props.selected])

    return (
        <>
            <Modal className="modal-60w" show={props.show}>
                <Modal.Header closeButton>
                    <Modal.Title>Aggiungi Stages</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Alert variant="warning" show={alert.show}>
                        Hai {alert.isSelect ? 'selezionato' : 'deselezionato'} {alert.number} elementi.
                        <Alert.Link as={Button} variant="link"
                                    onClick={() => handleOnSelectAllPages(alert.isSelect)}>{alert.isSelect ? 'Seleziona' : 'Deseleziona'} tutti
                            i {props.value ? props.value.length : 0} elementi.</Alert.Link>
                    </Alert>
                    <BootstrapTable
                        keyField='identifier'
                        columns={columnStages}
                        data={props.value}
                        selectRow={selectRow}
                        pagination={paginationFactory()}
                    />

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.onClose}>Close</Button>
                    <Button variant="primary" onClick={handleOnSubmit}>Save</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default StageDialog;