import {actionTypes} from "../../constants/action-types";



const initialState = {
    categoryList: {items: []},
    tagList: {items: []},
    compatiblesensorsList: {items: []},
    compatiblecontentsList: {items: []},
    typeList: {items: []},
    agetargetList: {items: []},
    stylesList: {items: []},
    interestsList: {items: []},
    typologiesList: {items: []},

};

export const reducer =
    (state = initialState, action) => {
        switch (action.type) {
            case actionTypes.TaxonomySuccess : {

                const list = action.payload.type + 'List';

                return {...state, [list] : action.payload.data}
            }
            case actionTypes.TaxonomySaveSuccess :
                return {...state, messages : action.payload}

            default:
                break;
        }

        return state;
    }
