import React, {useCallback, useEffect, useState} from "react";
import CMDetail from "./CMDetail";
import {useFormikContext} from "formik";
import GenericTable from "../common/datadisplay/GenericTable";
import moment from 'moment';
import {v4 as uuidv4} from "uuid";
import ConfirmDialog from "../common/ConfirmDialog";

export const APP_LOCAL_DATETIME_FORMAT = 'YYYY-MM-DDThh:mm:ss.SSS[Z]';

function CM(props) {
    const [cm, setCm] = useState(null);
    const [cmDelete, setCmDelete] = useState(null);
    const [show, setShow] = useState(false);
    const [cmList, setCmList] = useState(null);
    const [modalConfirm, setModalConfirm] = useState(false);
    const formik = useFormikContext();

    useEffect(() => {
        if (formik.values.contents) {
            setCmList(formik.values.contents.CM);
        }
    }, [formik.values.contents])

    const handleNew = useCallback(() => {
        let identifier = uuidv4()
        let objectGroupId = identifier.substring(0, 4)
        setCm({
            type: "",
            identifier: identifier,
            objectGroupId: objectGroupId,
            version: "",
            title: "",
            description: "",
            visore_vr: "",
            language: "",
            baseUrl: "",
            url: "",
            size: "",
            extension: "",
            bitrate: "",
            format: "",
            duration: "",
            created_date: moment(new Date()).format(APP_LOCAL_DATETIME_FORMAT),
            last_date: moment(new Date()).format(APP_LOCAL_DATETIME_FORMAT)
        });
    })

    const handleCancel = useCallback(() => {
        setCm(null);
        props.onBack();
    })

    const handleSave = useCallback((values) => {
        const contentsCM = formik.values.contents.CM;
        if (cm.index >= 0) {
            contentsCM[cm.index] = {...values}

        } else {
            contentsCM.push({...values})
        }
        formik.setFieldValue(`contents.CM`, contentsCM)
        setCm(null);
        props.onBack();

    })

    const handleDetailClick = useCallback((e, value, index) => {
        setCm({...value, index: index});
        setShow(true);
        // handleToggle()
    })

    const handleDelete = useCallback((event, data) => {
        setModalConfirm(true)
        setCmDelete(data);
    })

    const toggleConfirm = () => {
        setModalConfirm(!modalConfirm);
    }

    const deleteItem = useCallback(() => {
        // this.props.onDelete(this.state.data.identifier);
        const newList = cmList.filter((item) => item.identifier !== cmDelete.identifier);
        setCmList(newList)
        formik.setFieldValue(`contents.CM`, newList)
        setModalConfirm(false);

    })
    const headers = ["title", "description", "type", "format"];

    return (
        <>
            {!cm ?
                <>
                    <GenericTable headers={headers} values={cmList} handleDetailClick={handleDetailClick}
                                  handleNew={handleNew} buttonTitleNew={"Nuovo CM"} buttonIconNew="fa-folder-plus"
                                  handleDeleteClick={handleDelete}
                    />
                    <ConfirmDialog modal={modalConfirm} onToggle={toggleConfirm}
                                   action={deleteItem}
                                   title="Cancella l'item CM" content="Sei sicuro di voler cancellare l'item CM?"/>
                </>
                :
                <CMDetail value={cm} handleSave={handleSave} handleBack={handleCancel}/>
            }
        </>
    )
}

export default CM;