import {all} from "redux-saga/effects";
import * as auth from "../sagas/authSaga";
import * as taxonomy from "../sagas/taxonomySaga";
import * as poi from "../sagas/poiSaga";
import * as place from "./placeSaga";

export default function* rootSaga() {
    /** @ToDo: Inserire nella sezione all tutti i saga separati da comma */
    yield all([
        auth.saga(),
        taxonomy.saga(),
        poi.saga(),
        place.saga()
    ]);
}
