import React, {Component} from "react";
import {injectIntl} from "react-intl";
import GenericTable from "../common/datadisplay/GenericTable";
import ConfirmDialog from "../common/ConfirmDialog";
import BootstrapTable from "react-bootstrap-table-next";
import {Button} from "react-bootstrap";
import paginationFactory from "react-bootstrap-table2-paginator";


class PoiList extends Component {
    //static contextType = SubheaderContext
    constructor(props) {

        super(props);

        this.state = {
            readOnly: false,
            modalConfirm: false,
            headers: [
                {
                    dataField: 'title',
                    text: 'Title'
                },
                {
                    dataField: 'tag_type',
                    text: 'Tag Type'
                },
                {
                    dataField: 'ac',
                    text: 'AC'
                },
                {
                    dataField: 'cm',
                    text: 'CM'
                },
                {
                    dataField: 'lm',
                    text: 'LM'
                },
                {
                    dataField: 'df1',
                    isDummyField: true,
                    text: 'Action',
                    headerClasses: 'text-right',
                    classes: 'text-right',
                    formatter: (cellContent, row) => {
                        return (
                            <>
                                <Button variant="link" size="sm"
                                        onClick={(e) => this.handleDetailClick(e, row)}>
                                    <i className="fas fa-eye"></i>
                                </Button>
                                <Button variant="link" size="sm"
                                        onClick={(e) => this.handleDelete(e, row)}>
                                    <i className="fas fa-trash"></i>
                                </Button>
                            </>
                        )
                    }
                }
            ],
            data: null
        }

        this.loadMore = this.loadMore.bind(this)

    }

    toggleConfirm = () => {
        this.setState(prevState => ({
            modalConfirm: !prevState.modalConfirm
        }));
    };


    componentDidMount() {
        this.handleDetailClick = this.handleDetailClick.bind(this);

    }


    handleDetailClick = (event, data) => {

        this.props.onDetail(data.identifier);

    }

    handleDelete = (event, data) => {
        this.setState({data: data, modalConfirm: true});


    }

    deleteItem() {
        this.props.onDelete(this.state.data.identifier);
        this.setState({data: null, modalConfirm: false});
    }

    loadMore() {
        this.props.loadMore();
    }

    render() {

        return (
            <>
                <div className="row">
                    <ConfirmDialog modal={this.state.modalConfirm} onToggle={this.toggleConfirm}
                                   action={() => this.deleteItem()}
                                   title={this.props.dialogTitle} content={this.props.dialogContent}/>
                    <div className="col-lg-12 col-xxl-12">
                        <div className="card card-custom gutter-b">
                            <div className="card-header">
                                <div className="card-title">
                                    <h3 className="card-label font-weight-bolder text-dark">{this.props.title}</h3>
                                </div>
                            </div>
                            <div className="card-body">
                                {this.props.values && (
                                    <BootstrapTable
                                        keyField='identifier'
                                        data={this.props.values.items}
                                        columns={this.state.headers}
                                        bootstrap4
                                        striped
                                        hover={true}
                                        bordered={false}
                                        pagination={paginationFactory()}
                                    />


                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}


export default injectIntl(PoiList);
