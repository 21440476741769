import React, {useCallback, useEffect, useState} from "react";
import {useFormikContext} from "formik";
import GenericTable from "../common/datadisplay/GenericTable";
import ACDetail from "./ACDetail";
import moment from 'moment';
import {v4 as uuidv4} from "uuid";
import ConfirmDialog from "../common/ConfirmDialog";

export const APP_LOCAL_DATETIME_FORMAT = 'YYYY-MM-DDThh:mm:ss.SSS[Z]';

function AC(props) {
    const [show, setShow] = useState(false);
    const [acList, setAcList] = useState(null);
    const [ac, setAc] = useState(null);
    const [acDelete, setAcDelete] = useState(null);
    const [modalConfirm, setModalConfirm] = useState(false);
    const formik = useFormikContext();

    const headers = ["title", "description", "role", "age_target"];


    useEffect(() => {

        if (formik.values.contents) {
            setAcList(formik.values.contents.AC);
        }
    }, [formik.values.contents])


    const handleDetailClick = useCallback((e, value, index) => {

        setAc({...value, index: index});
        setShow(true);

        // handleToggle()
    })


    const handleCancel = useCallback(() => {

        setAc(null);
        props.onBack();
    })

    const handleNew = useCallback(() => {
        let identifier = uuidv4()
        let objectGroupId = identifier.substring(0, 4)
        setAc({
            identifier: identifier,
            objectGroupId: objectGroupId,
            version: "",
            title: "",
            description: "",
            description_file: "",
            role: "",
            tag_status: "",
            sensor_type: "",
            sensor_uid: "",
            type: "",
            visiting_minutes: "",
            history_character: "",
            history_event: "",
            language: "",
            age_target: "",
            tag_emo: "",
            created_date: moment(new Date()).format(APP_LOCAL_DATETIME_FORMAT),
            last_date: moment(new Date()).format(APP_LOCAL_DATETIME_FORMAT),
            data: {
                main: [],
                audios: [],
                videos: [],
                images: []
            }
        });
    })

    const handleSave = useCallback((values) => {
            const contentsAC = formik.values.contents.AC;
            if (ac.index >= 0) {
                contentsAC[ac.index] = {...values}

            } else {

                contentsAC.push({...values})
            }
            formik.setFieldValue(`contents.AC`, contentsAC)
            setAc(null);
            props.onBack();
        }
    )

    const handleDelete = useCallback((event, data) => {
        setModalConfirm(true)
        setAcDelete(data);
    })

    const toggleConfirm = () => {
        setModalConfirm(!modalConfirm);
    }

    const deleteItem = useCallback(() => {
       // this.props.onDelete(this.state.data.identifier);
        const newList = acList.filter((item) => item.identifier !== acDelete.identifier);
        setAcList(newList)
        formik.setFieldValue(`contents.AC`, newList)
        setModalConfirm(false);

    })


    return (


        <>
            {!ac ?
                <>
                    <GenericTable headers={headers} values={acList}
                                  handleDetailClick={handleDetailClick}
                                  handleNew={handleNew} buttonTitleNew={"Nuovo AC"} buttonIconNew="fa-folder-plus"
                                  handleDeleteClick={handleDelete}
                    />
                    <ConfirmDialog modal={modalConfirm} onToggle={toggleConfirm}
                                   action={deleteItem}
                                   title="Cancella l'item AC" content="Sei sicuro di voler cancellare l'item AC?"/>
                </>
                :
                <ACDetail value={ac} handleSave={handleSave} handleBack={handleCancel}/>}

        </>


    )
}

export default AC;