import React, {Component} from "react";
import {injectIntl} from "react-intl";
import {
    Button,
    Modal
} from "react-bootstrap";


class ConfirmDialog extends Component {

    render() {

        const { modal, onToggle, title, content, action } = this.props;
        console.log("Modal: ", modal);
        return (
        <>
        <Modal show={modal} onHide={onToggle} >
        <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
            <p>{content}</p>
        </Modal.Body>

        <Modal.Footer>
            <Button variant="secondary" onClick={onToggle}>No</Button>
            <Button variant="primary" onClick={action} onDoubleClick={() => alert('Attendere il completamento dell\'operazione!')}>Si</Button>
        </Modal.Footer>
        </Modal>
        </>
        );
    }
}
export default injectIntl(ConfirmDialog);
