import React, {useCallback, useEffect, useState} from "react";
import {useHeaderDialog} from "../../../_metronic/layout/_core/MetronicHeaderDialog";
import {useFormikContext} from "formik";
import {Col, Form} from "react-bootstrap";
import * as taxonomy from "../../../redux/taxonomy/taxonomy.action";
import {connect} from "react-redux";

import PoiGroup from "./PoiGroup";
import CompatibleGroup from "./CompatibleGroup";
import PathGroup from "./PathGroup";
import {v4 as uuidv4} from "uuid";
import Path from "./Path";
import * as poi from "../../../redux/poi/poi.action";

import moment from "moment";
export const APP_LOCAL_DATETIME_FORMAT = 'YYYY-MM-DDThh:mm:ss.SSS[Z]';


const PlaceDetail = (props) => {

    const headerDialog = useHeaderDialog();
    const formik = useFormikContext();

    const [place, setPlace] = useState(null);
    const [path, setPath] = useState(null);

    const handleCancel = useCallback(() => {
        props.onCancel();


    })

    const resetDefaultButton = () => {
        const actions = [
            {action: {handleCancel}, icon: 'cancel', label: 'Cancel', variant: 'secondary', className: 'mr-2'},
            {action: {handleSave}, icon: 'save', label: 'Salva Luogo'},
        ]
        headerDialog.setActions(actions)
    }

    const handleCancelPath = useCallback(() => {
        //props.onCancel();

        setPath(null)
        resetDefaultButton();

    })

    const handleSave = () => {
        //console.log('handleSave', formik.values)
        formik.handleSubmit();
    }

    const handleNewPath = useCallback(() => {
        let identifier = uuidv4()
        let objectGroupId = identifier.substring(0, 4)
        console.log("Value.pois: ", formik.values.pois);
        setPath(
            {
                poisSelected: formik.values.pois,
                path: {
                    identifier: identifier,
                    objectGroupId: objectGroupId,
                    title: '',
                    version: '',
                    description: '',
                    fileName: '',
                    age_target: '',
                    tag_interest: '',
                    tag_style: '',
                    duration: '',
                    color: '',
                    created_date: moment(new Date()).format(APP_LOCAL_DATETIME_FORMAT),
                    last_date: moment(new Date()).format(APP_LOCAL_DATETIME_FORMAT),
                    main: [],
                    stages: []
                }
            }
        )
        console.log(props.pois);

    });

    //aggiunta
    const handleDeletePath = useCallback((values) => {
        console.log("handleDeletePath: ", values)
        formik.setFieldValue("paths", formik.values.paths.filter((item) => item.identifier !== values.identifier))
    })

    const handleDetailPath = useCallback((values) => {
        console.log("handleDetailPath: ", formik.values.pois);
        setPath({poisSelected: formik.values.pois, path: values})
    })

    const handleSavePath = useCallback((value) => {

        const index = formik.values.paths.findIndex(element => element.identifier == value.identifier)


        if (index >= 0) {
            formik.values.paths[index] = value;
        } else {
            formik.values.paths.push(value);
        }
        setPath(null);
        resetDefaultButton()
        // formik.setFieldValue('paths')

        console.log("Sto inserendo il luogo: " + JSON.stringify(value))

    })

    // aggiunta
    const handleDelPath = useCallback((value) => {
        const paths = formik.values.paths.filter(element => element.identifier !== value.identifier)
        setPath({poiSelected: formik.values.pois, path: paths});
        console.log("Sto cancellando l'itinerario: " + JSON.stringify(value))
    })

    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";
    };

    const normalize = (field, val = '') => {
        return {...formik.getFieldProps(field), value: formik.values[field] || val}
    }

    useEffect(() => {

        props.taxonomyList('agetarget');
        props.poisCount();

        const actions = [
            {action: {handleCancel}, icon: 'cancel', label: 'Cancel', variant: 'secondary', className: 'mr-2'},
            {action: {handleSave}, icon: 'save', label: 'Salva Luogo'},
        ]
        headerDialog.setActions(actions)

    }, [])


    useEffect(() => {
        setPlace(props.data)
    }, [props.data])


    useEffect(() => {
        props.poiList(0, props.count.total);
    }, [props.count.total])

    useEffect(() => {
        const list = props.pois.items.filter(item => formik.values.pois.some(p => p.identifier === item.identifier)).map(p => p.contents).flat();
        console.log("LISTA: ", list)
        const paths = formik.values.paths.map(path => ({
            ...path,
            stages: path.stages.map(stage => ({...stage, ...list.find(l => stage.content_id === l.identifier)}))
        }))
        formik.setFieldValue('paths', paths);

    }, [props.poi, formik.values.pois])

    return (
        <>

            {place && (
                !path ? (
                    <>

                        <Form.Group controlId="formTitle">
                            <Form.Label>Title</Form.Label>
                            <Form.Control type="text"
                                          placeholder="Title"
                                          {...formik.getFieldProps("title")}
                                          className={`${getInputClasses("title")}`}/>
                            {formik.touched.title && formik.errors.title ? (
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block text-danger">{formik.errors.title}</div>
                                </div>
                            ) : null}
                        </Form.Group>
                        <Form.Row>
                            <Form.Group as={Col} controlId="formVersion">
                                <Form.Label>Versione</Form.Label>
                                <Form.Control type="text"
                                              placeholder="Versione"
                                              {...normalize("version")}
                                              className={`${getInputClasses("version")}`}/>
                                {formik.touched.version && formik.errors.version ? (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block text-danger">{formik.errors.version}</div>
                                    </div>
                                ) : null}
                            </Form.Group>
                            <Form.Group as={Col} controlId="formType">
                                <Form.Label>Type</Form.Label>
                                <Form.Control type="text"
                                              placeholder="Type"
                                              {...normalize("type")}/>
                            </Form.Group>
                            <Form.Group as={Col} controlId="formAgeTarget">
                                <Form.Label>Age target</Form.Label>
                                <Form.Control as="select" {...normalize("age_target")}
                                              className={`${getInputClasses("age_target")}`}>
                                    <option key="0" value={''}>Seleziona un valore</option>
                                    {props.agetargets && (
                                        props.agetargets.items.map((t) => (
                                            <option key={t.key} value={t.key}>{t.value}</option>
                                        ))
                                    )}

                                </Form.Control>
                                {formik.touched.age_target && formik.errors.age_target ? (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block text-danger">{formik.errors.age_target}</div>
                                    </div>
                                ) : null}

                            </Form.Group>
                        </Form.Row>
                        <Form.Group controlId="formDescription">
                            <Form.Label>Description</Form.Label>
                            <Form.Control as="textarea" rows={3} {...formik.getFieldProps("description")}  />
                        </Form.Group>

                        <CompatibleGroup/>

                        <PoiGroup/>

                        <PathGroup onCreate={handleNewPath} onDetail={handleDetailPath} onDelete={handleDeletePath}/>

                    </>
                ) : (
                    <Path onSave={handleSavePath} value={path} onCancel={handleCancelPath}/>
                )

            )}
        </>
    )
}

const mapStateToProps = ({taxonomy, poi}) => {
    return {
        agetargets: taxonomy.agetargetList,
        count: poi.poiCount,
        pois: poi.poisList

    };
}

export default connect(mapStateToProps, {...taxonomy.actions, ...poi.actions})(PlaceDetail);