import {actionTypes, startValue, rowsValue} from "../../constants/action-types";


export const actions = {
    poiUploadAndSaveMedia: (payload) => ({type: actionTypes.UploadAndSave, payload: payload}),
    poiInitialize: () => ({type: actionTypes.PoiInitialize}),
    poiDelete: (id) => ({type: actionTypes.PoiDelete, payload: {id}}),
    poiSave: (payload, history) => ({type: actionTypes.PoiSave, payload: payload, history: history}),
    poiList: (start = startValue, rows = rowsValue) => ({type: actionTypes.Pois, payload: {start, rows}}),
    poisCount: () => ({type: actionTypes.PoisCount}),
    poiById: (id, history) => ({type: actionTypes.PoiById, payload: {id}, history: history}),
}