import {actionTypes, startValue, rowsValue} from "../../constants/action-types";


const initialState = {
    place: {}
}
export const actions = {
    placeList: (start = startValue, rows = rowsValue) => ({type: actionTypes.Places, payload: {start, rows}}),
    placeById: (id, history) => ({type: actionTypes.PlaceById, payload: {id}, history: history}),
    placeDelete: (id) => ({type: actionTypes.PlaceDelete, payload: {id}}),
    placeSave: (payload, history) => ({type: actionTypes.PlaceSave, payload: payload, history: history}),
    placeInitialize: () => ({type: actionTypes.PlaceInitialize})
}