import React, {useCallback} from "react";
import {Button, Table} from "react-bootstrap";
import {injectIntl} from "react-intl";


function GenericTable(props) {

    const {intl, pagination = false} = props;

    const header = props.headers ? props.headers : [];
    const values = props.values ? props.values : [];
    const {size, total, page} = props;

    const loadMore = useCallback(() => {
        props.loadMore()
    })

    return (
        <>

            <Table responsive striped hover size="sm">
                <thead>
                <tr>
                    {header.map((key, indexTh) => (
                        <th key={indexTh}>{intl.formatMessage({id: key.toUpperCase(),})}</th>

                    ))}
                    <th key="headerAction">
                        {props.buttonTitleNew && (
                            <Button className="float-right"
                                    size="sm"
                                    variant="outline-light"
                                    onClick={() => props.handleNew()}>
                                <i className={props.buttonIconNew && `fas ${props.buttonIconNew}`}/> {props.buttonTitleNew}
                            </Button>
                        )}</th>
                </tr>
                </thead>

                <tbody>
                {values && values.map((value, indexTr) => {
                    return (
                        <tr key={indexTr}>
                            {
                                header.map((key, indexTd) => (
                                    <td key={indexTd} className="align-middle">{value[key]}</td>
                                ))

                            }
                            {(props.handleDetailClick || props.handleDeleteClick) && (
                                <td key="columnAction">
                                    {props.handleDeleteClick && (
                                        <Button className="float-right" variant="link" size="sm"
                                                onClick={(e) => props.handleDeleteClick(e, value)}>
                                            <i className="fas fa-trash"></i>
                                        </Button>
                                    )}
                                    {props.handleDetailClick && (
                                        <Button className="float-right" variant="link" size="sm"
                                                onClick={(e) => props.handleDetailClick(e, value, indexTr)}>
                                            <i className="fas fa-eye"></i>
                                        </Button>
                                    )}

                                </td>
                            )}
                        </tr>
                    )
                })
                }
                </tbody>


            </Table>
            {pagination && (total > size * (page + 1)) &&(
                <div className="row justify-content-md-center">
                    <div className="col col-sm-2">
                        <Button onClick={loadMore}>Load more...</Button>
                    </div>
                </div>
            )}
        </>

    );

};

export default injectIntl(GenericTable);

