import React, {useCallback, useEffect} from "react";
import {Button, Form} from "react-bootstrap";
import {handleHeaderDialogClick, useHeaderDialog} from "../../../_metronic/layout/_core/MetronicHeaderDialog";
import {useSubheader} from "../../../_metronic/layout";
import {Formik} from "formik";

import {useHistory} from "react-router-dom";
import {connect} from "react-redux";

import * as place from "../../../redux/place/place.action";
import * as Yup from "yup";
import {injectIntl} from "react-intl";
import PlaceDetail from "../../components/place/PlaceDetail";


function PlaceDetailPage(props) {
    const {intl} = props

    const subheader = useSubheader();
    const headerDialog = useHeaderDialog();
    const history = useHistory();

    const GeneralSchema = Yup.object().shape({
        title: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(200, "Maximum 200 symbols")
            .required(intl.formatMessage({id: "GENERIC.VALIDATION.REQUIRED_FIELD",})),
        age_target: Yup.string()
            .required(intl.formatMessage({id: "GENERIC.VALIDATION.REQUIRED_FIELD",}))
    });

    const handleSubmit = useCallback((values) => {
        console.log(values)
        props.placeSave(values, history)

    })

    const handleCancel = useCallback(() => {
        history.push("/luoghi/list")
    })

    useEffect(() => {
        const {id} = props.match.params

        if (id !== 'new') {
            subheader.setTitle(`Dettaglio Luogo ${id}`)
            props.placeById(id, history)
        } else {
            subheader.setTitle("Nuovo Luogo")
            props.placeInitialize()
        }
        subheader.setActions(null);
    }, [])

    useEffect(() => {

    }, [headerDialog, props.place]);


    return (
        <>
            <div className="row">
                <div className="col-lg-12 col-xxl-12">
                    <div className="card card-custom gutter-b">
                        <div className="card-header">
                            <div className="card-title">
                                <h3 className="card-label font-weight-bolder text-dark">
                                    {headerDialog && (
                                        <>
                                            {!headerDialog.title ? "Dettaglio Luogo " : headerDialog.title}
                                        </>
                                    )}
                                </h3>
                            </div>
                            <div className="card-toolbar">
                                {headerDialog && headerDialog.actions && (
                                    <>
                                        {headerDialog.actions.map((singleAction, index) => (
                                            <Button key={index}
                                                    className={singleAction.className}
                                                    variant={singleAction.variant ? singleAction.variant : 'primary'}
                                                    onClick={() => handleHeaderDialogClick(singleAction.action)}><i
                                                className={`fas fa-${singleAction.icon}`}/> {singleAction.label}
                                            </Button>

                                        ))}
                                    </>
                                )}

                            </div>
                        </div>
                        <div className="card-body">
                            <Formik
                                enableReinitialize={true}
                                initialValues={props.place}
                                validationSchema={GeneralSchema}
                                onSubmit={(values, {setSubmitting}) => {
                                    setTimeout(() => {
                                        handleSubmit(values);
                                        setSubmitting(true);
                                    }, 400);
                                }}>
                                {({
                                      values,
                                      errors,
                                      touched,
                                      handleChange,
                                      handleBlur,
                                      handleSubmit,
                                      isSubmitting,
                                  }) => (

                                    <Form onSubmit={handleSubmit}>
                                        <PlaceDetail data={props.place} onSave={handleSubmit} onCancel={handleCancel}/>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}


const mapStateToProps = ({place}) => {
    return {
        place: place.place
    };
};

export default injectIntl(connect(mapStateToProps, place.actions)(PlaceDetailPage));