import axios from "axios";
import {actionTypes} from "../constants/action-types";
import {call, put, takeLatest, takeEvery} from "redux-saga/effects";
import {NotificationManager} from "react-notifications";

export function* saga() {

    yield takeEvery(actionTypes.Taxonomies, taxonomyListWorkerSaga);
    yield takeLatest(actionTypes.TaxonomySave, taxonomySaveWorkerSaga);
}

export function* taxonomyListWorkerSaga({payload}) {

    const url = process.env.REACT_APP_SERVICE_TAXONOMY_BASE_URL + payload + "/list.json";
    try {
        const results = yield call(fetchTaxonomy, url);
        yield put({type: actionTypes.TaxonomySuccess, payload: {data: results.data, type: payload}});
    } catch (e) {
        yield put({type: actionTypes.TaxonomyError});
    }
}

export function* taxonomySaveWorkerSaga({payload}) {

    try {
        const results = yield call(saveTaxonomy, payload.data);
        if(results.data === "OK") {
            yield put({type: actionTypes.TaxonomySuccess, payload: {data: payload.data, type: payload.type}});
            NotificationManager.info(payload.msg, "Info", 3000, null, null, "filled")
        } else {
            NotificationManager.error("Errore imprevisto.", "Errore", 3000, null, null, "filled")
        }

    } catch (e) {
        console.error('Error', e)
        yield put({type: actionTypes.TaxonomyError});
    }
}

const fetchTaxonomy = async (url) => {
    return axios.get(url);
}

const saveTaxonomy = async (payload) => {

    return axios.post(process.env.REACT_APP_SERVICE_TAXONOMY_SAVE_URL, {...payload});
}


/*
function* contentsListSaga() {
    try {
        const result = yield call(getTaxonomyList, process.env.REACT_APP_SERVICE_CONTENTS_LIST);
        console.log(result.data);
        yield put({type: actionTypes.CategoryListLoaded, payload: result.data});
    } catch (error) {
        yield put({type: actionTypes.CategoryListError, payload: "AUTH.VALIDATION.INVALID_LOGIN"});
    }


}

*/