import React, {useCallback, useEffect, useState} from "react";
import {useFormik} from "formik";
import {Col, Form} from "react-bootstrap";
import GenericTable from "../common/datadisplay/GenericTable";
import VideoDialog from "../common/modal/VideoDialog";
import {useHeaderDialog} from "../../../_metronic/layout/_core/MetronicHeaderDialog";
import * as Yup from "yup";
import {injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import * as taxonomy from "../../../redux/taxonomy/taxonomy.action";


const LMDetail = (props) => {
    const {intl} = props
    const headerDialog = useHeaderDialog();

    const [show, setShow] = useState(false);
    const [video, setVideo] = useState(null);
    const [videolist, setVideolist] = useState(null);
    const LMSchema = Yup.object().shape({
        title: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(intl.formatMessage({id: "GENERIC.VALIDATION.REQUIRED_FIELD",})),
        raggio: Yup.string()
            .matches(/^[-+]?\d*[\.\,]{0,1}\d+$||[-+]?\d*\.{0,1}\d+$/, intl.formatMessage({id: "GENERIC.VALIDATION.REQUIRED_FIELD.ISNUMERIC",}))
            .nullable(true),
        inclinazione_min: Yup.string()
            .matches(/^[-+]?\d*[\.\,]{0,1}\d+$||[-+]?\d*\.{0,1}\d+$/, intl.formatMessage({id: "GENERIC.VALIDATION.REQUIRED_FIELD.ISNUMERIC",}))
            .nullable(true),
        inclinazione_max: Yup.string()
            .matches(/^[-+]?\d*[\.\,]{0,1}\d+$||[-+]?\d*\.{0,1}\d+$/, intl.formatMessage({id: "GENERIC.VALIDATION.REQUIRED_FIELD.ISNUMERIC",}))
            .nullable(true),
        direzione_sx: Yup.string()
            .matches(/^[-+]?\d*[\.\,]{0,1}\d+$||[-+]?\d*\.{0,1}\d+$/, intl.formatMessage({id: "GENERIC.VALIDATION.REQUIRED_FIELD.ISNUMERIC",}))
            .nullable(true),
        direzione_dx: Yup.string()
            .matches(/^[-+]?\d*[\.\,]{0,1}\d+$||[-+]?\d*\.{0,1}\d+$/, intl.formatMessage({id: "GENERIC.VALIDATION.REQUIRED_FIELD.ISNUMERIC",}))
            .nullable(true),
    });

    const formik = useFormik({
        initialValues: {...props.value},
        validationSchema: LMSchema,
        onSubmit: values => {
            headerDialog.setShow(false);
            headerDialog.setTitle(null);
            headerDialog.setActions(null);
            console.log("Sto salvando l'oggetto LM: " + JSON.stringify(values))
            props.handleSave(values)
        }
    });

    const handleDetailClick = useCallback((e, value, index) => {
        setVideo({data: value, index: index});
        setShow(true);
    })

    const handleDeleteClick = useCallback((e, value, index) => {
        const videoList = formik.values.video_list;
        formik.setFieldValue(`video_list`,
                                   videoList.filter(item => item.label !== value.label && item.url !== value.url))
        setShow(false);
    })

    const handleNew = (type) => {
        setShow(true);
        setVideo({data: {label: '', x: 0, y: 0, url: '', extension: ''}});
    }


    const handleSaveVideo = (values) => {
        console.log('handleSaveVideo ', values)
        const videoList = formik.values.video_list;
        // const index = mediaValue.index >=0
        if (video.index >= 0) {
            videoList[video.index] = {...values}
        } else {
            videoList.push({...values})
        }


        setShow(false);
        formik.setFieldValue(`video_list`, [...videoList])
        setVideo(null);

    }

    const handleCloseVideo = () => {
        setShow(false);
        setVideo(null);
    }


    const handleSave = () => {

       /* headerDialog.setShow(false);
        headerDialog.setTitle(null);
        headerDialog.setActions(null);
        */

       console.log('SAVE')
        formik.handleSubmit();
    }

    const handleBack = () => {

        headerDialog.setShow(false);
        headerDialog.setTitle(null);
        headerDialog.setActions(null);
        props.handleBack();
    }

    const normalize = (field, val = '') => {
        return {...formik.getFieldProps(field), value: formik.values[field] || val}
    }


    const header = ['label', 'x', 'y', 'url']


    useEffect(() => {
        props.taxonomyList('typologies')
        headerDialog.setShow('LM')
        headerDialog.setTitle(props.value && props.value.title != '' ? props.value.title : 'Nuovo LM');
        const actions = [
            {action: {handleBack}, icon: 'arrow-left', label: 'Indietro', variant: 'secondary', className: 'mr-2'},
            {action: {handleSave}, icon: 'save', label: 'Salva LM'},
        ]
        headerDialog.setActions(actions)
    }, []);



    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";
    };

    useEffect(() => {
        setVideolist(formik.values.video_list);
    }, [formik.values.video_list])

    return (
        <>
            <Form.Group controlId="formTitle">
                <Form.Label>Title</Form.Label>
                <Form.Control type="text"
                              placeholder="Title" {...normalize(`title`)}  className={`${getInputClasses("title")}`}/>
                {formik.touched.title && formik.errors.title ? (
                    <div className="fv-plugins-message-container">
                        <div className="fv-help-block text-danger">{formik.errors.title}</div>
                    </div>
                ) : null}
            </Form.Group>
            <Form.Row>
                <Form.Group controlId="formType" as={Col}>
                    <Form.Label>Type</Form.Label>
                    <Form.Control as="select" {...formik.getFieldProps('type')} className={`${getInputClasses("type")}`}>
                        <option key="0" value={''}>Seleziona un valore</option>
                        {props.typologies && props.typologies.items.map((option, index) => {
                            return (<option key={option.key} value={option.key}>{option.value}</option>)
                        })}
                    </Form.Control>
                    {formik.touched.type && formik.errors.type ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block text-danger">{formik.errors.type}</div>
                        </div>
                    ) : null}
                </Form.Group>
                <Form.Group controlId="formRaggio" as={Col}>
                    <Form.Label>Raggio</Form.Label>
                    <Form.Control placeholder="raggio" {...normalize(`raggio`)}  className={`${getInputClasses("raggio")}`}/>
                    {formik.touched.raggio && formik.errors.raggio ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block text-danger">{formik.errors.raggio}</div>
                        </div>
                    ) : null}
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Form.Group controlId="formInclMin" as={Col}>
                    <Form.Label>Inclinazione Min</Form.Label>
                    <Form.Control placeholder="Inclinazione Min" {...normalize(`inclinazione_min`)}   className={`${getInputClasses("inclinazione_min")}`}/>
                    {formik.touched.inclinazione_min && formik.errors.inclinazione_min ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block text-danger">{formik.errors.inclinazione_min}</div>
                        </div>
                    ) : null}
                </Form.Group>
                <Form.Group controlId="formInclMax" as={Col}>
                    <Form.Label>Inclinazione Max</Form.Label>
                    <Form.Control placeholder="Inclinazione Max" {...normalize(`inclinazione_max`)} className={`${getInputClasses("inclinazione_max")}`}/>
                    {formik.touched.inclinazione_max && formik.errors.inclinazione_max ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block text-danger">{formik.errors.inclinazione_max}</div>
                        </div>
                    ) : null}
                </Form.Group>
                <Form.Group controlId="formDirDx" as={Col}>
                    <Form.Label>Direzione DX</Form.Label>
                    <Form.Control placeholder="Direzione DX" {...normalize(`direzione_dx`)}  className={`${getInputClasses("direzione_dx")}`}/>
                    {formik.touched.direzione_dx && formik.errors.direzione_dx ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block text-danger">{formik.errors.direzione_dx}</div>
                        </div>
                    ) : null}
                </Form.Group>
                <Form.Group controlId="formDirSx" as={Col}>
                    <Form.Label>Direzione SX</Form.Label>
                    <Form.Control placeholder="Direzione SX" {...normalize(`direzione_sx`)}   className={`${getInputClasses("direzione_sx")}`}/>
                    {formik.touched.direzione_sx && formik.errors.direzione_sx ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block text-danger">{formik.errors.direzione_sx}</div>
                        </div>
                    ) : null}
                </Form.Group>
            </Form.Row>

            <GenericTable headers={header}
                          values={videolist}
                          handleDetailClick={(e, value, index) => handleDetailClick(e, value, index)}
                          handleDeleteClick={(e, value, index) => handleDeleteClick(e, value, index)}
                          handleNew={() => handleNew()} buttonTitleNew={`Nuovo`} buttonIconNew={'fa-video'}
            />

            {video && (
                <VideoDialog value={video} show={show} handleSave={handleSaveVideo} handleClose={handleCloseVideo}/>
            )
            }

        </>
    )
}


const mapStateToProps = ({taxonomy}) => {
    return {
        typologies: taxonomy.typologiesList
    };
};


export default injectIntl(connect(mapStateToProps, taxonomy.actions)(LMDetail));