import React, {useEffect} from "react";
import {Col, Form} from "react-bootstrap";
import {Formik, useFormik} from "formik";
import {useHeaderDialog} from "../../_metronic/layout/_core/MetronicHeaderDialog";
import * as Yup from "yup";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import * as taxonomy from "../../redux/taxonomy/taxonomy.action";

const TaxonomyDetail = (props) => {

    const {intl} = props
    const headerDialog = useHeaderDialog();

    const handleBack = () => {
        headerDialog.setShow(false);
        headerDialog.setTitle(null);
        headerDialog.setActions(null);
        props.onBack();
    }

    const TaxonomySchema = Yup.object().shape({
        key: Yup.string()
            .required(intl.formatMessage({id: "GENERIC.VALIDATION.REQUIRED_FIELD",})),
        value: Yup.string()
            .required(intl.formatMessage({id: "GENERIC.VALIDATION.REQUIRED_FIELD",})),
    });

    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";
    };

    const handleSave = () => {
        formik.handleSubmit();
    }

    useEffect(() => {
            const actions = [
                {action: {handleBack}, icon: 'arrow-left', label: 'Indietro', variant: 'secondary', className: 'mr-2'},
                {action: {handleSave}, icon: 'save', label: 'Salva Tassonomia'},
            ]
            headerDialog.setActions(actions)
        },
        []);
    useEffect(() => {

    }, [props.value]);

    const formik = useFormik({
        initialValues: {...props.value},
        validationSchema: TaxonomySchema,
        enableReinitialize: true,
        onSubmit: values => {
            headerDialog.setShow(false);
            headerDialog.setTitle(null);
            headerDialog.setActions(null);
            props.onSave(values)
        }
    });

    return (
        <>
            <Form onSubmit={formik.handleSubmit}>
                <Form.Row>
                    <Form.Group as={Col} controlId="formGridLabel">
                        <Form.Label>Key</Form.Label>
                        <Form.Control type="text" name="key"
                                      placeholder="Enter key" {...formik.getFieldProps(`key`)}
                                      disabled={props.readOnly}
                                      className={`${getInputClasses("key")}`}/>
                        {formik.touched.key && formik.errors.key ? (
                            <div className="fv-plugins-message-container">
                                <div className="fv-help-block text-danger">{formik.errors.key}</div>
                            </div>
                        ) : null}
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridValue">
                        <Form.Label>Value</Form.Label>
                        <Form.Control type="text" name="value"
                                      placeholder="Enter value" {...formik.getFieldProps(`value`)}
                                      className={`${getInputClasses("value")}`}/>
                        {formik.touched.value && formik.errors.value ? (
                            <div className="fv-plugins-message-container">
                                <div className="fv-help-block text-danger">{formik.errors.value}</div>
                            </div>
                        ) : null}
                    </Form.Group>
                </Form.Row>
            </Form>
        </>
    )
}

export default injectIntl(connect(null, null)(TaxonomyDetail));

