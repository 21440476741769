import axios from "axios";
import {actionTypes} from "../constants/action-types";
import {call, put, takeEvery} from "redux-saga/effects";
import {NotificationManager} from "react-notifications";
import {v4 as uuidv4} from "uuid";

export function* saga() {
    yield takeEvery(actionTypes.Pois, poiListWorkerSaga);
    yield takeEvery(actionTypes.PoisCount, poiListCountWorkerSaga);
    yield takeEvery(actionTypes.PoiById, poiWorkerSaga);

    yield takeEvery(actionTypes.UploadAndSave, poiWorkerUploadSaga);
    yield takeEvery(actionTypes.PoiDelete, poiDeleteWorkerSaga);
    yield takeEvery(actionTypes.PoiSave, poiSaveWorkerSaga);
}

export function* poiWorkerUploadSaga(action) {
    try {
        const result = yield call(writeContentPoi, action.payload);

        NotificationManager.success('Upload file avvenuto con successo', 'Upload');
    } catch (e) {
        yield put({type: actionTypes.UploadError});
    }
}

export function* poiDeleteWorkerSaga(action) {
    try {
        const {id} = action.payload
        const result = yield call(deletePoi, id);
        // controllo il ritorno:


        if (result.data.error === true) {
            let places = ""
            result.data.place.map(item => {
                places = places + " - '" + item.title + "'"
            })
            let msg = `Impossibile cancellare il poi. Ci sono ${result.data.total} luoghi che lo contengono.`
            let msg2 = `Luoghi: ${places}`

            NotificationManager.error(msg, "Errore delete POI", 5000, null, null, "filled")
            NotificationManager.error(msg2, "Errore delete POI", 5000, null, null, "filled")
        } else if (result.data.msg === "OK") {
            NotificationManager.info("POI cancellato.", "delete POI", 5000, null, null, "filled")
            console.log('poiDeleteWorkerSaga')
            yield put({type: actionTypes.Pois, payload: {}});
        } else {
            NotificationManager.error("Errore imprevisto durante la cancellazione.", "delete POI", 5000, null, null, "filled")
        }

    } catch (e) {
        yield put({type: actionTypes.PoiError});
    }
}

export function* poiSaveWorkerSaga(action) {
    try {
        let poi =  action.payload;

        if (!poi.identifier || poi.identifier === "") {
            const identifier = uuidv4()
            const objectGroupId = identifier.substring(0, 4)
            const filename = identifier + ".xml"
            poi = {
                ...poi,
                identifier: identifier,
                objectGroupId: objectGroupId,
                fileName: filename
            }
        }

        const result = yield call(savePoi, poi);
        NotificationManager.info("POI salvato correttamente.", "Save POI", 5000, null, null, "filled")
        action.history.push('/poi/list');
    } catch (e) {
        const msg = `Impossibile salvare il poi.`
        NotificationManager.error(msg, "Errore Save POI", 5000, null, null, "filled")
        yield put({type: actionTypes.PoiError});
    }
}


export function* poiWorkerSaga(action) {
    try {
        const {id} = action.payload
        const result = yield call(fetchPoiById, id);
        console.log("RESULT: ", result)
        if(Array.isArray(result.data) && !result.data.length){
            console.log("ERRORE: ", result.data)
            action.history.push(`/itemnotfound/poi/${id}`);
        } else {
            console.log("NORMALE: ", result.data)
            //console.log("RESULT POI: ", result);
            yield put({type: actionTypes.PoiSuccess, payload: result.data});
        }

    } catch (e) {
        yield put({type: actionTypes.PoiError});
    }
}

export function* poiListWorkerSaga(action) {
    console.log('poiListWorkerSaga', action)
    try {
        const {start, rows} = action.payload
        const params = {start : start, rows: rows}
        const results = yield call(fetchPoi, params);
        const more = start > 0

        yield put({type: actionTypes.PoisSuccess, payload: {...results.data, more: more}});
    } catch (e) {
        console.log('poiListWorkerSaga error')
        yield put({type: actionTypes.PoisError});
    }
}
export function* poiListCountWorkerSaga(action) {

    try {
        const params = {total : 1}
        const results = yield call(fetchPoi, params);
        console.log('poiListCountWorkerSaga', results)
        yield put({type: actionTypes.PoiCountSuccess, payload: {...results.data}});
    } catch (e) {
        yield put({type: actionTypes.PoisError});
    }
}

const fetchPoi = async (params) => {


    return axios.get(process.env.REACT_APP_SERVICE_POI_LIST_URL, {
        params: params
    });
}

const deletePoi = async (id) => {
    return axios.get(process.env.REACT_APP_SERVICE_POI_DELETE + `?identifier=${id}`);
}

const savePoi = async (data) => {
    return axios.post(process.env.REACT_APP_SERVICE_POI_SAVE_URL, data);
}

const fetchPoiById = (id) => {
    return axios.get(process.env.REACT_APP_SERVICE_POI_BY_ID_URL + id + '.json');
}

const writeContentPoi = (data) => {
    return axios.post(process.env.REACT_APP_SERVICE_POI_WRITE, data);
}


