export const actionTypes = {
    Login: "[Login] Action",
    LoginRequest: "[Login Request] Action",
    LoginError: "[Login Error] Action",
    Logout: "[Logout] Action",
    Register: "[Register] Action",
    UserRequested: "[Request User] Action",
    UserLoaded: "[Load User] Auth API",
    SetUser: "[Set User] Action",

    Taxonomies: "[Taxonomies List] Action",
    TaxonomySuccess: "[Taxonomy Success] Crafter API",
    TaxonomyError: "[Taxonomy Error] Crafter API",

    TaxonomySave: "[Taxonomies Save] Action",
    TaxonomySaveSuccess: "[Taxonomies Save Success] Crafter API",

    Pois: "[Pois List] Action",
    PoisCount: "[Pois Count] Action",
    PoisSuccess: "[Pois Success] Crafter API",
    PoisError: "[Pois Error] Crafter API",
    PoiById: "[PoiById Get] Action",
    PoiInitialize: "[Poi Initialize] Action",
    PoiDelete: "[Poi Delete] Action",
    PoiSave: "[Poi Save] Action",
    PoiSuccess: "[Poi Success] Crafter API",
    PoiCountSuccess: "[Poi Count Success] Crafter API",
    PoiError: "[Poi Error] Crafter API",

    Places: "[Places List] Action",
    PlacesSuccess: "[Places Success] Crafter API",
    PlaceInitialize: "[Place Initialize] Action",
    PlaceById: "[PlaceById Get] Action",
    PlaceSave: "[Place Save] Action",
    PlaceDelete: "[Place Delete] Action",
    PlaceSuccess: "[Place Success] Crafter API",
    PlaceError: "[Place Error] Crafter API",

    UploadAndSave: "[Upload Save] Crafter API",
    UploadError: "[Upload Error] Crafter API",

    Contents: "[Contents List] Action",
    ContentsListLoaded: "[Contents List Loaded] Action",
    ContentsListError: "[Contents List Error] Action",
};

export const startValue = 0;
export const rowsValue = 100;