import React, {useState} from "react";
import {injectIntl} from "react-intl";
import GenericTable from "./common/datadisplay/GenericTable";
import ConfirmDialog from "./common/ConfirmDialog";


function TaxonomyList(props) {

    const [modalConfirm, setModalConfirm] = useState(false);
    const [data, setData] = useState(null);
    const headers = ["key", "value"];



    const toggleConfirm = () => {
        setModalConfirm(!modalConfirm);
    };



    const handleDetailClick = (event, value, index) => {
        props.onChange(value);

    }


    const handleDelete = (event, data) => {
        setModalConfirm(true)
        setData(data)
        //this.setState({data: data, modalConfirm: true});
        console.log("HandleDelete")

    }

    const deleteItem = () => {
        props.onDelete(data);
        setData(null)
        setModalConfirm(false)
        //this.setState({data: null, modalConfirm: false});
    }


    return (
        <>

            {props.values && (
                <>
                    <GenericTable headers={headers} values={props.values.items}
                                  handleDeleteClick={handleDelete}
                                  handleDetailClick={handleDetailClick}></GenericTable>

                    < ConfirmDialog modal={modalConfirm} onToggle={toggleConfirm}
                                    action={() => deleteItem()}
                                    title={props.dialogTitle} content={props.dialogContent}/>
                </>
            )}
        </>
    )

}


export default injectIntl(TaxonomyList);
