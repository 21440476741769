import React, {useCallback, useEffect, useReducer, useState} from "react";
import * as taxomomy from "../../../redux/taxonomy/taxonomy.action";
import TaxonomyList from "../../components/TaxonomyList";
import {useSubheader} from "../../../_metronic/layout";
import {injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import {Button} from "react-bootstrap";
import TaxonomyDetail from "../../components/TaxonomyDetail";
import {handleHeaderDialogClick, useHeaderDialog} from "../../../_metronic/layout/_core/MetronicHeaderDialog";


function ContentPage(props) {

    const subheader = useSubheader();
    const headerDialog = useHeaderDialog();

    const [taxonomy, setTaxonomy] = useState(null);

    const [isReadOnly, setIsReadOnly] = useState(false);    

    const handleOpen = () => {
        // dispatch({type: 'open', payload: 'new'})
        setTaxonomy({key: '', value: ''});
        setIsReadOnly(false)
        headerDialog.setShow("CONT");
        

    }

    const handleCancel = useCallback(() => { setTaxonomy(null) });

    const handleChange = useCallback((value) => {
        headerDialog.setShow("CONT");
        setTaxonomy(value);
        setIsReadOnly(true)
        //dispatch({type: 'change', payload: 'old'})
    });


    const handleSubmit = useCallback((values) => {

        let items = props.contents.items;
        let msg = ''
        const index = items.findIndex(obj => obj.key === values.key);
        if (index >= 0) {
            items[index] = values;
            msg = 'Elemento modificato'
        } else {
            items = [...items, values]
            msg = 'Elemento aggiunto'
        }
        values = {...props.contents, items: items}
        let payload = {data: values, type: 'compatiblecontents', msg: msg}
        props.saveTaxonomy(payload);
    });

    const handleDelete = (values) => {
        let items = props.contents.items;
        const index = items.findIndex(obj => obj.key === values.key);
        if (index !== -1) {
            items.splice(index, 1);
            values = {...props.contents, items: items}
            let payload = {data: values, type: 'compatiblecontents', msg: 'Elemento cancellato'}
            props.saveTaxonomy(payload);
        } else {
            console.log("Elemento non presente")
        }
    }


    useEffect(() => {
        subheader.setActions([{action: {handleOpen}, icon: 'file', label: 'Nuovo'}])
        headerDialog.setShow(null);
        headerDialog.setTitle(null);
        headerDialog.setActions(null);

        props.taxonomyList('compatiblecontents')
    }, []);

    useEffect(() => {

    }, [taxonomy]);

    return (
        <>
            <div className="row">

                <div className="col-lg-12 col-xxl-12">
                    <div className="card card-custom gutter-b">
                        <div className="card-header">
                            <div className="card-title">
                                <h3 className="card-label font-weight-bolder text-dark">Lista compatible content</h3>
                            </div>


                            <div className="card-toolbar">
                                <>
                                    {headerDialog.actions && headerDialog.actions.map((singleAction, index) => (
                                        <Button key={index}
                                                className={singleAction.className}
                                                variant={singleAction.variant ? singleAction.variant : 'primary'}
                                                onClick={() => handleHeaderDialogClick(singleAction.action)}><i
                                            className={`fas fa-${singleAction.icon}`}/> {singleAction.label}
                                        </Button>

                                    ))}
                                </>
                            </div>

                        </div>
                        <div className="card-body">
                            {headerDialog.show == 'CONT' && (
                                <>
                                    <TaxonomyDetail value={taxonomy} onBack={ handleCancel} onSave={ handleSubmit} readOnly={isReadOnly}/>
                                    <div className="separator separator-dashed my-5"></div>
                                </>
                            )}
                            <TaxonomyList values={props.contents}
                                          onChange={handleChange}
                                          onDelete={handleDelete}
                                          dialogTitle={'Cancella compatible content'}
                                          dialogContent={'Sei sicuro di voler cancellare il compatible content?'}/>
                        </div>
                    </div>
                </div>
            </div>
        </>);
}


const mapStateToProps = ({taxonomy}) => {
    return {
        contents: taxonomy.compatiblecontentsList
    };
};

export default injectIntl(connect(mapStateToProps, taxomomy.actions)(ContentPage));