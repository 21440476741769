import {Col, Form} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {useFormik} from "formik";
import {useHeaderDialog} from "../../../_metronic/layout/_core/MetronicHeaderDialog";
import * as taxonomy from "../../../redux/taxonomy/taxonomy.action";
import connect from "react-redux/es/connect/connect";
import {injectIntl} from "react-intl";
import * as Yup from "yup";


const CMDetail = (props) => {

    const {intl} = props

    const headerDialog = useHeaderDialog();
    const [audio, setAudio] = useState(null);
    const [mediaValue, setMediaValue] = useState(null);
    const [fileUpload, setFileUpload] = useState({isValid: false, size: null, name: null});

    const handleSave = () => {
        formik.handleSubmit();
    }

    const handleBack = () => {
        headerDialog.setShow(false);
        headerDialog.setTitle(null);
        headerDialog.setActions(null);
        props.handleBack();
    }

    const upload = (event) => {
        const upl = event.target.files[0];
        var extension = upl.name.split('.').pop();

        if (upl) {
            setFileUpload({isValid: true, size: upl.size, name: upl.name});

            formik.setFieldValue('format', upl.type)
            formik.setFieldValue('extension', extension)
            formik.setFieldValue('url', `${process.env.REACT_APP_CONSTANT_IMAGE_BASE_PATH}${upl.name}`)
            formik.setFieldValue('baseUrl', process.env.REACT_APP_CONSTANT_IMAGE_BASE_URL)
            formik.setFieldValue('size', upl.size)
        }
    }

    const CMSchema = Yup.object().shape({
        title: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(200, "Maximum 200 symbols")
            .required(intl.formatMessage({id: "GENERIC.VALIDATION.REQUIRED_FIELD",})),
    });

    const formik = useFormik({
        initialValues: {...props.value},
        validationSchema: CMSchema,
        onSubmit: values => {
            headerDialog.setShow(false);
            headerDialog.setTitle(null);
            headerDialog.setActions(null);
            props.handleSave(values)
        }
    });

    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";
    };

    useEffect(() => {
        props.taxonomyList('type')

        headerDialog.setShow('CM')
        headerDialog.setTitle(props.value && props.value.title !== '' ? props.value.title : 'Nuovo CM');
        const actions = [
            {action: {handleBack}, icon: 'arrow-left', label: 'Indietro', variant: 'secondary', className: 'mr-2'},
            {action: {handleSave}, icon: 'save', label: 'Salva Dettaglio'},
        ]
        headerDialog.setActions(actions)

    }, []);

    const normalize = (field, val = '') => {
        return {...formik.getFieldProps(field), value: formik.values[field] || val}
    }


    return (
        <>
            <Form.Group controlId="formTitle">
                <Form.Label>Title</Form.Label>
                <Form.Control type="text" placeholder="Title" {...normalize("title")}  className={`${getInputClasses("title")}`} />
                {formik.touched.title && formik.errors.title ? (
                    <div className="fv-plugins-message-container">
                        <div className="fv-help-block text-danger">{formik.errors.title}</div>
                    </div>
                ) : null}
            </Form.Group>
            <Form.Group controlId="formType">
                <Form.Label>Description</Form.Label>
                <Form.Control as="textarea" placeholder="Description" rows={3} {...normalize("description")} />
            </Form.Group>

            <Form.Group controlId="formMediaUrl">
                <Form.Label>File</Form.Label>
                <Form.File custom>
                    <Form.File.Input isValid={fileUpload.isValid} onChange={(event) => upload(event)}/>
                    <Form.File.Label data-browse="Carica">
                        Carica file input
                    </Form.File.Label>
                    <Form.Control.Feedback
                        type="valid">{`${fileUpload.name} caricato con successo (${fileUpload.size} byte)`}</Form.Control.Feedback>
                </Form.File>

            </Form.Group>

            <Form.Row>
                <Form.Group as={Col} md="6" lg="3" controlId="formType">
                    <Form.Label>Type</Form.Label>
                    <Form.Control as="select" {...normalize("type")}>
                        <option key="0" value={''}>Seleziona un valore</option>
                        {props.type && (
                            props.type.items.map((t) => (
                                <option key={t.key}>{t.value}</option>
                            ))
                        )}

                    </Form.Control>
                </Form.Group>
                <Form.Group as={Col} md="6" lg="3" controlId="formVersion">
                    <Form.Label>Version</Form.Label>
                    <Form.Control type="text" placeholder="Version" {...normalize("version")} />
                </Form.Group>
                <Form.Group as={Col} md="6" lg="3" controlId="formVisoreVR">
                    <Form.Label>Visore VR</Form.Label>
                    <Form.Control type="text" placeholder="Visore VR" {...normalize("visore_vr")} />
                </Form.Group>
                <Form.Group as={Col} md="6" lg="3" controlId="formLanguage">
                    <Form.Label>Language</Form.Label>
                    <Form.Control type="text" placeholder="Language" {...normalize("language")} />
                </Form.Group>
                <Form.Group as={Col} md="6" lg="3" controlId="formExtension">
                    <Form.Label>Extension</Form.Label>
                    <Form.Control type="text" placeholder="Extension" {...normalize("extension")} />
                </Form.Group>
                <Form.Group as={Col} md="6" lg="3" controlId="formBitrate">
                    <Form.Label>Bitrate</Form.Label>
                    <Form.Control type="text" placeholder="Bitrate" {...normalize("bitrate")} />
                </Form.Group>
                <Form.Group as={Col} md="6" lg="3" controlId="formFormat">
                    <Form.Label>Format</Form.Label>
                    <Form.Control type="text" placeholder="Format" {...normalize("format")} />
                </Form.Group>
                <Form.Group as={Col} md="6" lg="3" controlId="formDuration">
                    <Form.Label>Duration</Form.Label>
                    <Form.Control type="text" placeholder="Duration" {...normalize("duration")} />
                </Form.Group>
            </Form.Row>
        </>
    )
}

const mapStateToProps = ({taxonomy}) => {
    return {
        type: taxonomy.typeList
    };
};

export default injectIntl(connect(mapStateToProps, taxonomy.actions)(CMDetail));