
import {actionTypes} from "../../constants/action-types";



export const actions = {
    login: (payload)  => ({type: actionTypes.Login, payload: payload }),
    logout: () => ({ type: actionTypes.Logout }),
   /* login: (authToken) => ({ type: actionTypes.Login, payload: { authToken } }),
    register: (authToken) => ({
        type: actionTypes.Register,
        payload: { authToken },
    }),
    */

    /*requestUser: (user) => ({
        type: actionTypes.UserRequested,
        payload: { user },
    }),
    */
    fulfillUser: (user) => ({ type: actionTypes.UserLoaded, payload: { user } }),
    /*
    setUser: (user) => ({ type: actionTypes.SetUser, payload: { user } }),
    */
};

