import {persistReducer} from "redux-persist";
import storage from "redux-persist/es/storage";
import {actionTypes} from "../../constants/action-types";
import sessionStorage from "redux-persist/lib/storage/session";



const initialAuthState = {
    user: undefined,
    authToken: undefined,
};

export const reducer = persistReducer(
    { storage: sessionStorage, key: "v713-smarttour-auth", whitelist: ["user", "authToken"] },
    (state = initialAuthState, action) => {
        switch (action.type) {
            /*
            case actionTypes.Login: {
                const { authToken } = action.payload;

                return { authToken, user: undefined };
            }

            case actionTypes.Register: {
                const { authToken } = action.payload;

                return { authToken, user: undefined };
            }
*/
            case actionTypes.Logout: {
                // TODO: Change this code. Actions in reducer aren't allowed.
                return initialAuthState;
            }

            case actionTypes.LoginError: {
                return {...initialAuthState, error: action.payload};
            }

            case actionTypes.UserLoaded: {
                const  user  = action.payload;
                console.log('USER Loaded ', action.payload)
                return { ...state, user: {...user} };
            }
/*
            case actionTypes.SetUser: {
                const { user } = action.payload;
                return { ...state, user };
            }
*/
            default:
                return state;
        }
    }
);
