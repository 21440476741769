/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";

export function AsideMenuList({ layoutProps, menu }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url) ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open ` : "";
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>

        {/* Applications */}
        {/* begin::section */}
        <li className="menu-section ">
          <h4 className="menu-text">{menu.title}</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        {/* end:: section */}



          {menu.submenu.map((item, index) => (
              <li key={index}
                  className={`menu-item menu-item-submenu ${getMenuItemActive(item.url,false)}`}
                  aria-haspopup="true"
                  data-menu-toggle="hover"
              >
                  <NavLink className="menu-link menu-toggle" to={item.url}>
                      <span className="svg-icon menu-icon">
                        <i className={`fas ${item.icon}`} />

                      </span>
                      <span className="menu-text">{item.title}</span>
                  </NavLink>
                  {item.submenu && (
                    <div className="menu-submenu">
                        <i className="menu-arrow" />
                        <ul className="menu-subnav">
                            <li className="menu-item menu-item-parent" aria-haspopup="true">
                              <span className="menu-link">
                                <span className="menu-text">{item.title}</span>
                              </span>
                            </li>
                            {item.submenu.map((itemsubmenu, indexsubmenu) => (

                                <li key={indexsubmenu} className={`menu-item ${getMenuItemActive(itemsubmenu.url)}`} aria-haspopup="true">
                                    <NavLink className="menu-link" to={itemsubmenu.url}>
                                        <i className="menu-bullet menu-bullet-dot">
                                            <span />
                                        </i>
                                        <span className="menu-text">{itemsubmenu.title}</span>
                                    </NavLink>
                                </li>

                              ))}

                        </ul>
                    </div>
                  )}
              </li>


          ))}

      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
