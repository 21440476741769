import {Col, Form} from "react-bootstrap";
import React, {useCallback, useEffect, useState} from "react";
import {useFormik} from "formik";
import {useHeaderDialog} from "../../../_metronic/layout/_core/MetronicHeaderDialog";
import GenericTable from "../common/datadisplay/GenericTable";
import MediaDialog from "../common/modal/MediaDialog";
import * as Yup from "yup";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import * as taxonomy from "../../../redux/taxonomy/taxonomy.action";
import {v4 as uuidv4} from "uuid";
import moment from "moment";

export const APP_LOCAL_DATETIME_FORMAT = 'YYYY-MM-DDThh:mm:ss.SSS[Z]';


const ACDetail = (props) => {
    const {intl} = props
    const headerDialog = useHeaderDialog();
    const [main, setMain] = useState(null);
    const [audios, setAudios] = useState(null);
    const [images, setImages] = useState(null);
    const [videos, setVideos] = useState(null);
    const [show, setShow] = useState(false);
    const [mediaValue, setMediaValue] = useState(null);


    const handleSave = () => {

        formik.handleSubmit();
    }

    const handleBack = () => {

        headerDialog.setShow(false);
        headerDialog.setTitle(null);
        headerDialog.setActions(null);
        props.handleBack();
    }


    const handleDetailClick = useCallback((e, value, index, type) => {
        setMediaValue({data: value, index: index, type: type});

        setShow(true);
    })

    const handleDeleteClick = useCallback((e, value, index, type) => {
        const mediaList = formik.values.data[type];
        formik.setFieldValue(`data[${type}]`, mediaList.filter((item) => item.identifier !== value.identifier))
        setShow(false);
    })

    const handleSaveMedia = (values) => {
        const mediaList = formik.values.data[mediaValue.type];
        // const index = mediaValue.index >=0
        if (mediaValue.index >= 0) {
            mediaList[mediaValue.index] = {...values}
        } else {
            mediaList.push({...values})
        }

        console.log(mediaList)
        setShow(false);
        formik.setFieldValue(`data[${mediaValue.type}]`, [...mediaList])
        setMediaValue(null);

    }

    const handleCloseMedia = () => {
        setShow(false);
        setMediaValue(null);
    }

    const ACSchema = Yup.object().shape({
        title: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(200, "Maximum 200 symbols")
            .required(intl.formatMessage({id: "GENERIC.VALIDATION.REQUIRED_FIELD",})),
        age_target: Yup.string()
            .min(3, "Minimum 3 symbols")
            .required(intl.formatMessage({id: "GENERIC.VALIDATION.REQUIRED_FIELD",}))
            .nullable(true),
        visiting_minutes: Yup.string()
            .min(1, 'Must be exactly 1 digits')
            .max(3, 'Must be exactly 3 digits')
            .matches(/^[0-9]+$/, intl.formatMessage({id: "GENERIC.VALIDATION.REQUIRED_FIELD.ISNUMERIC",}))
            .nullable(true),
        description_file: Yup.string()
            .matches(/^(?!.*[\\\/]\s+)(?!(?:.*\s|.*\.|\W+)$)(?:[a-zA-Z]:)?(?:(?:[^<>:"\|\?\*\n])+(?:\/\/|\/|\\\\|\\)?)+$/,
                intl.formatMessage({id: "AC.VALIDATION.REQUIRED_FIELD.DESCRIPTION_FILE",}))
            .nullable(true)
    });
    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";
    };

    const formik = useFormik({
        initialValues: {...props.value},
        validationSchema: ACSchema,
        onSubmit: values => {
            headerDialog.setShow(false);
            headerDialog.setTitle(null);
            headerDialog.setActions(null);
            props.handleSave(values)
        }
    });

    const handleNew = (type) => {
        let identifier = uuidv4()
        let objectGroupId = identifier.substring(0, 4)
        let date = moment(new Date()).format(APP_LOCAL_DATETIME_FORMAT)
        setShow(true);
        setMediaValue({
            data: {
                title: '',
                mime_type: '',
                url: '',
                identifier: identifier,
                objectGroupId: objectGroupId,
                created_date: date,
                last_date: date
            }, type: type
        });
    }

    const headers = ["title", "mime_type", "url"];


    const Media = (props) => {
        const icons = {
            'audios': 'fa-music',
            'videos': 'fa-video',
            'images': 'fa-image',
            'main': 'fa-photo-video'

        }
        return (
            <div className={`card ${props.className}`}>
                <div className="card-body">
                    <h6>{props.title}</h6>
                    <div className="separator separator-dashed"></div>
                    <GenericTable headers={props.header}
                                  values={props.value}
                                  handleDetailClick={(e, value, index) => handleDetailClick(e, value, index, props.type)}
                                  handleDeleteClick={(e, value, index) => handleDeleteClick(e, value, index, props.type)}
                                  handleNew={() => handleNew(props.type)} buttonTitleNew={`Nuovo`}
                                  buttonIconNew={icons[props.type]}
                    />
                </div>
            </div>
        )
    }

    useEffect(() => {
        headerDialog.setShow('AC')
        headerDialog.setTitle(props.value && props.value.title != '' ? props.value.title : 'Nuovo AC');
        const actions = [
            {action: {handleBack}, icon: 'arrow-left', label: 'Indietro', variant: 'secondary', className: 'mr-2'},
            {action: {handleSave}, icon: 'save', label: 'Salva Dettaglio'},
        ]
        headerDialog.setActions(actions)
        props.taxonomyList('agetarget');
    }, []);

    useEffect(() => {

        setMain(formik.values.data.main);
        setAudios(formik.values.data.audios);
        setImages(formik.values.data.images);
        setVideos(formik.values.data.videos);
    }, [formik.values.data])

    useEffect(() => {

    }, [mediaValue])

    const normalize = (field, val = '') => {
        return {...formik.getFieldProps(field), value: formik.values[field] || val}
    }
    return (
        <>
            {props.value && (
                <>
                    <Form.Group controlId="formTitle">
                        <Form.Label>Title</Form.Label>
                        <Form.Control type="text"
                                      placeholder="Title" {...normalize(`title`)}
                                      className={`${getInputClasses("title")}`}/>
                        {formik.touched.title && formik.errors.title ? (
                            <div className="fv-plugins-message-container">
                                <div className="fv-help-block text-danger">{formik.errors.title}</div>
                            </div>
                        ) : null}
                    </Form.Group>
                    <Form.Group controlId="formDescription">
                        <Form.Label>Description</Form.Label>
                        <Form.Control as="textarea"
                                      rows={3} {...normalize(`description`)}  />
                    </Form.Group>
                    <Form.Group controlId="formDescriptionFile">
                        <Form.Label>Description File</Form.Label>
                        <Form.Control
                            placeholder="Description File" {...normalize('description_file')}
                            className={`${getInputClasses("description_file")}`}/>
                        {formik.touched.description_file && formik.errors.description_file ? (
                            <div className="fv-plugins-message-container">
                                <div className="fv-help-block text-danger">{formik.errors.description_file}</div>
                            </div>
                        ) : null}
                    </Form.Group>
                    <Form.Row>
                        <Form.Group as={Col} controlId="formRole">
                            <Form.Label>Role</Form.Label>
                            <Form.Control
                                placeholder="Role"  {...normalize(`role`)}  />
                        </Form.Group>
                        <Form.Group as={Col} controlId="formType">
                            <Form.Label>Type</Form.Label>
                            <Form.Control
                                placeholder="Type" {...normalize(`type`)}/>
                        </Form.Group>
                        <Form.Group as={Col} controlId="formVersion">
                            <Form.Label>Version</Form.Label>
                            <Form.Control
                                placeholder="Version" {...normalize(`version`)}/>
                        </Form.Group>
                        <Form.Group as={Col} controlId="formLanguage">
                            <Form.Label>Language</Form.Label>
                            <Form.Control
                                placeholder="Language" {...normalize(`language`)}/>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} controlId="formTagEmo">
                            <Form.Label>Tag emo</Form.Label>
                            <Form.Control
                                placeholder="Tag emo" {...normalize(`tag_emo`)}/>
                        </Form.Group>
                        <Form.Group as={Col} controlId="formTagStatus">
                            <Form.Label>Tag Status</Form.Label>
                            <Form.Control
                                placeholder="Tag Status" {...normalize(`tag_status`)}/>
                        </Form.Group>
                        <Form.Group as={Col} controlId="formVisitingMinutes">
                            <Form.Label>Visiting minutes</Form.Label>
                            <Form.Control
                                placeholder="Visiting minutes" {...normalize(`visiting_minutes`)}
                                className={`${getInputClasses("visiting_minutes")}`}/>
                            {formik.touched.visiting_minutes && formik.errors.visiting_minutes ? (
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block text-danger">{formik.errors.visiting_minutes}</div>
                                </div>
                            ) : null}
                        </Form.Group>
                        <Form.Group as={Col} controlId="formAgeTarget">
                            <Form.Label>Age target</Form.Label>

                            <Form.Control as="select" {...normalize("age_target")}
                                          className={`${getInputClasses("age_target")}`}>
                                <option key="0" value={''}>Seleziona un valore</option>
                                {props.agetargets && (
                                    props.agetargets.items.map((t) => (
                                        <option key={t.key} value={t.key}>{t.value}</option>
                                    ))
                                )}

                            </Form.Control>
                            {formik.touched.age_target && formik.errors.age_target ? (
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block text-danger">{formik.errors.age_target}</div>
                                </div>
                            ) : null}

                        </Form.Group>

                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} controlId="formSensorType">
                            <Form.Label>Sensor Type</Form.Label>
                            <Form.Control
                                placeholder="Sensor Type" {...normalize(`sensor_type`)}/>
                        </Form.Group>
                        <Form.Group as={Col} controlId="formSensorUid">
                            <Form.Label>Sensor Uid</Form.Label>
                            <Form.Control
                                placeholder="Sensor Uid" {...normalize(`sensor_uid`)}/>
                        </Form.Group>
                        <Form.Group as={Col} controlId="formHistoryCharacter">
                            <Form.Label>History character</Form.Label>
                            <Form.Control
                                placeholder="History character" {...normalize(`history_character`)}/>
                        </Form.Group>
                        <Form.Group as={Col} controlId="formHistoryEvent">
                            <Form.Label>History event</Form.Label>
                            <Form.Control
                                placeholder="History event" {...normalize(`history_event`)}/>
                        </Form.Group>
                    </Form.Row>

                    <Media title="Main" value={main} header={headers} type="main" className="mt-7"/>
                    <Media title="Images" value={images} header={headers} type="images" className="mt-7"/>
                    <Media title="Videos" value={videos} header={headers} type="videos" className="mt-7"/>
                    <Media title="Audios" value={audios} header={headers} type="audios" className="mt-7"/>
                    {mediaValue && (
                        <MediaDialog value={mediaValue} show={show} parent={{...props.value, type: 'AC'}}
                                     handleSave={handleSaveMedia} handleClose={handleCloseMedia}/>
                    )}

                </>
            )}

        </>
    )
}

const mapStateToProps = ({taxonomy}) => {
    return {
        agetargets: taxonomy.agetargetList,

    };
}
export default injectIntl(connect(mapStateToProps, taxonomy.actions)(ACDetail));