import React, {useEffect, useState} from "react";
import * as poi from "../../../redux/poi/poi.action";
import {useSubheader} from "../../../_metronic/layout/index";
import {injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import PoiList from "../../components/poi/PoiList";
import {useHeaderDialog} from "../../../_metronic/layout/_core/MetronicHeaderDialog";


function PoiPage(props) {
    const subheader = useSubheader();
    const headerDialog = useHeaderDialog();
    const [page, setPage] = useState(0);
    const size = 10;

    const handleOpen = () => {
        headerDialog.setShow(false);
        headerDialog.setTitle(null);
        headerDialog.setActions(null);
        props.poiInitialize();
        props.history.push('/poi/new');
    }

    const handleDelete = (id) => {

        props.poiDelete(id)


    }

    const handleDetail = (id) => {


        props.history.push("/poi/" + id);
        //props.poiById(id, props.history);

    }


    const loadMore = () => {

        const start = (page + 1) * size;
        props.poiList(start, size);
        setPage(page + 1);
    }


    useEffect(() => {
        headerDialog.setActions(null);
        headerDialog.setTitle(null);
        headerDialog.setShow(false);
        subheader.setActions([{action: {handleOpen}, icon: 'file', label: 'Nuovo'}])
        props.poiList()
    }, []);

    return (
        <>
            <PoiList values={props.pois} onDetail={handleDetail} onDelete={handleDelete} title={'Lista poi'}
                     dialogTitle={'Cancella poi'}
                     dialogContent={'Sei sicuro di voler cancellare il poi?'}
                     loadMore={loadMore}
                     page={page}
                     size={size}
            />

        </>);
}


const mapStateToProps = ({poi}) => {
    return {
        pois: poi.poisList
    };
};

export default injectIntl(connect(mapStateToProps, poi.actions)(PoiPage));