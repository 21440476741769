import React, {useEffect} from "react";
import {useHtmlClassService, useSubheader} from "../../layout";
import {toAbsoluteUrl} from "../../_helpers";
import {KTUtil} from "../../_assets/js/components/util";
import {Brand} from "../../layout/components/brand/Brand";

export function Dashboard() {
    const uiService = useHtmlClassService();
    const subheader = useSubheader();
    useEffect(() => {
        subheader.setActions(null);
    }, [])

    return <>

        <div className="row">
            <div className="col-lg-12 col-xxl-12 text-center">
                <Brand height="max-h-110px"/>
                <img style={{height:  parseInt(KTUtil.getViewPort().height) - 300 }}
                    src={toAbsoluteUrl("/media/bg/itinerario.png")}
                    alt="Itinerario"
                />
            </div>
        </div>


    </>;
}
