import React, {useEffect, useState} from "react";
import {Button, Col, Form, Modal} from "react-bootstrap";
import {useFormik} from "formik";
import {Input} from "react-select";
import connect from "react-redux/es/connect/connect";
import * as poi from "../../../../redux/poi/poi.action";
import * as Yup from "yup";
import {injectIntl} from "react-intl";

const VideoDialog = (props) => {
    const {intl} = props;
    const [fileUpload, setFileUpload] = useState({isValid: false, size: null, name: null});



    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";
    };
    const MediaSchema = Yup.object().shape({
        label: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(intl.formatMessage({id: "AUTH.VALIDATION.REQUIRED_FIELD",})),
        x: Yup.string()
            .matches(/^[-+]?\d*[\.\,]{0,1}\d+$||[-+]?\d*\.{0,1}\d+$/, intl.formatMessage({id: "GENERIC.VALIDATION.REQUIRED_FIELD.ISNUMERIC",}))
            .nullable(true),
        y: Yup.string()
            .matches(/^[-+]?\d*[\.\,]{0,1}\d+$||[-+]?\d*\.{0,1}\d+$/, intl.formatMessage({id: "GENERIC.VALIDATION.REQUIRED_FIELD.ISNUMERIC",}))
            .nullable(true),
    });
    const formik = useFormik({
        initialValues: {...props.value.data},
        validationSchema: MediaSchema,
        enableReinitialize: true,
        onSubmit: values => {
            // props.handleSave(values)

            console.log('Submit ', values)
            let data = new FormData();
            data.append("site", process.env.REACT_APP_SITE_NAME);
            data.append("path", process.env.REACT_APP_CONSTANT_IMAGE_BASE_PATH);
            data.append("file", values.file);

            props.poiUploadAndSaveMedia(data);
            props.handleSave(values);

        }
    });

    const upload = (event) => {
        const upl = event.target.files[0];
        console.log('Upload', upl)
        if (upl) {
            setFileUpload({isValid: true, size: upl.size, name: upl.name});
            var ext =  upl.name.split('.').pop();
            formik.setFieldValue('extension', ext)
            formik.setFieldValue('url', `${process.env.REACT_APP_CONSTANT_IMAGE_BASE_PATH}${upl.name}`)
            formik.setFieldValue('baseUrl', process.env.REACT_APP_CONSTANT_IMAGE_BASE_URL)
            formik.setFieldValue('size', upl.size)

            // aggiunta
            formik.setFieldValue("file",upl);
            formik.setFieldValue("new",true);

        }

    }

    useEffect(() => {
        // aggiunta
        formik.setFieldValue("new",false);
    }, []);

    useEffect(() => {
            console.log("FORMIK VALUES: ", formik.values)
    }, [formik.values]);

    return (
        <>
            {props.value && (
                <Modal show={props.show}>
                    <Modal.Header closeButton>
                        <Modal.Title>{formik.values.label}</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        {formik.status ? (
                            <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                                <div className="alert-text font-weight-bold">{formik.status}</div>
                            </div>
                        ) : (
                            <></>
                        )}
                        <Form.Group controlId="formVideoTitle" className="fv-plugins-icon-container">
                            <Form.Label>Label</Form.Label>
                            <Form.Control type="text"
                                          name="label"
                                          placeholder="Label" {...formik.getFieldProps(`label`)} className={`${getInputClasses("label")}`}/>
                            {formik.touched.label && formik.errors.label ? (
                            <div className="fv-plugins-message-container">
                                <div className="fv-help-block text-danger">{formik.errors.label}</div>
                            </div>
                            ) : null}
                        </Form.Group>
                        <Form.Group controlId="formVideoUrl">
                            <Form.Label>File</Form.Label>
                            <Form.File custom>
                                <Form.File.Input name="file" isValid={fileUpload.isValid} onChange={(event) => upload(event)}/>
                                <Form.File.Label data-browse="Carica">
                                    Carica file input
                                </Form.File.Label>
                                <Form.Control.Feedback
                                    type="valid">{`${fileUpload.name} caricato con successo (${fileUpload.size} byte)`}</Form.Control.Feedback>
                            </Form.File>

                        </Form.Group>
                        <Form.Group controlId="formVideoUrl">
                            <Form.Label>URL</Form.Label>
                            <Form.Control type="text" readOnly
                                          placeholder="URL" {...formik.getFieldProps(`url`)} />
                        </Form.Group>
                        <Form.Group controlId="formVideoExt">
                            <Form.Label>Extension</Form.Label>
                            <Form.Control type="text" readOnly
                                          placeholder="Extension" {...formik.getFieldProps(`extension`)} />
                        </Form.Group>
                        <Form.Row>
                            <Form.Group controlId="formVideoX" as={Col}>
                                <Form.Label>X</Form.Label>
                                <Form.Control placeholder="X" {...formik.getFieldProps(`x`)}
                                              className={`${getInputClasses(`x`)}`}/>
                                {formik.touched.x && formik.errors.x ? (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block text-danger">{formik.errors.x}</div>
                                    </div>
                                ) : null}
                            </Form.Group>
                            <Form.Group controlId="formVideoY" as={Col}>
                                <Form.Label>Y</Form.Label>
                                <Form.Control placeholder="Y" {...formik.getFieldProps(`y`)}
                                              className={`${getInputClasses(`y`)}`}/>
                                {formik.touched.y && formik.errors.y ? (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block text-danger">{formik.errors.y}</div>
                                    </div>
                                ) : null}
                            </Form.Group>
                        </Form.Row>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={props.handleClose}>Close</Button>
                        <Button variant="primary" onClick={formik.handleSubmit}>Save</Button>
                    </Modal.Footer>
                </Modal>
            )
            }
        </>
    )


}

export default injectIntl(connect(null, poi.actions)(VideoDialog));