import React, {useCallback, useEffect, useState} from "react";
import {injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import * as place from "../../../redux/place/place.action";
import PlaceListGroup from "../../components/place/PlaceList";
import {useSubheader} from "../../../_metronic/layout";
import PoiList from "../../components/poi/PoiList";


function PlacePage(props) {

    const subheader = useSubheader();
    const [page, setPage] = useState(0);
    const size = 10;

    const handleOpen = () => {
        props.history.push('/luoghi/new');
    }

    const handleDetail = useCallback((id) => {
        props.history.push("/luoghi/" + id);
        //props.poiById(id, props.history);

    })

    const handleDelete = useCallback((id) => {
        props.placeDelete(id)
    })



    const loadMore = () => {

        const start = (page + 1) * size;
        props.placeList(start, size);
        setPage(page + 1);
    }
    useEffect(() => {
        subheader.setActions([{action: {handleOpen}, icon: 'file', label: 'Nuovo'}])
        props.placeList();
    }, [])


    return (
        <PlaceListGroup values={props.places} onDelete={handleDelete} handleDetail={handleDetail}
                        dialogTitle={'Cancella luogo'}
                        dialogContent={'Sei sicuro di voler cancellare il luogo?'}
                        loadMore={loadMore}
                        page={page}
                        size={size}/>

    );

}
const mapStateToProps = ({place}) => {
    return {
        places: place.placeList
    };

}

const mapStateToDispatch = place.actions

export default connect(mapStateToProps, mapStateToDispatch)(PlacePage);