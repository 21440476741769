import * as poi from "../../../redux/poi/poi.action";
import React, {useCallback, useEffect} from "react";
import PoiDetail from "../../components/poi/PoiDetail";
import {Button, Form} from "react-bootstrap";
import {connect} from "react-redux";
import {injectIntl} from "react-intl";
import {handleHeaderDialogClick, useHeaderDialog} from "../../../_metronic/layout/_core/MetronicHeaderDialog";
import {useSubheader} from "../../../_metronic/layout";
import {Formik} from "formik";

import {useHistory} from "react-router-dom";
import * as Yup from "yup";


function PoiDetailPage(props) {
    const {intl} = props;
    const subheader = useSubheader();
    const headerDialog = useHeaderDialog();
    const history = useHistory();

    const GeneralSchema = Yup.object().shape({
        title: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(200, "Maximum 200 symbols")
            .required(intl.formatMessage({id: "GENERIC.VALIDATION.REQUIRED_FIELD",})),
        importance: Yup.string()
            .min(1, 'Must be exactly 1 digits')
            .max(3, 'Must be exactly 3 digits')
            .matches(/^[0-9]+$/, intl.formatMessage({id: "GENERIC.VALIDATION.REQUIRED_FIELD.ISNUMERIC",}))
            .nullable(true),
        lat: Yup.string()
            .matches(/^\s*((?=.*[1-9])\d*(?:\.\d{1,2}))|(?=.*[1-9])\d*(?:\,\d{1,2})?\s*$/,
                intl.formatMessage({id: "GENERIC.VALIDATION.REQUIRED_FIELD.DECIMAL",}))
            .nullable(true),
        lon: Yup.string()
            .matches(/^\s*((?=.*[1-9])\d*(?:\.\d{1,2}))|(?=.*[1-9])\d*(?:\,\d{1,2})?\s*$/,
                intl.formatMessage({id: "GENERIC.VALIDATION.REQUIRED_FIELD.DECIMAL",}))
            .nullable(true),
        telefono: Yup.string()
            .matches(/((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
                intl.formatMessage({id: "GENERIC.VALIDATION.REQUIRED_FIELD.PHONE",}))
            .nullable(true),
        email: Yup.string()
            .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                intl.formatMessage({id: "GENERIC.VALIDATION.REQUIRED_FIELD.EMAIL",}))
            .nullable(true),
        sito_web: Yup.string()
            .matches(/((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
                intl.formatMessage({id: "GENERIC.VALIDATION.REQUIRED_FIELD.WEBSITE",}))
            .nullable(true)
    });

    const handleSubmit = useCallback((values) => {
        console.log('SAVE', values)
        props.poiSave(values, history);
    })

    const handleCancel = () => {
        history.push('/poi/list')
    }

    useEffect(() => {
        const {id} = props.match.params

        if (id !== 'new') {
            subheader.setTitle(`Dettaglio POI ${id}`)
            props.poiById(id, history)
        } else {
            subheader.setTitle("Nuovo POI")
            props.poiInitialize()
        }
        subheader.setActions(null);
    }, [])


    useEffect(() => {
        //  headerDialog.setActions([{action: {handleSubmit}, icon: 'file', label: 'Salva Dettaglio'}])
    }, [headerDialog, props.poi]);


    return (
        <>
            <div className="row">
                <div className="col-lg-12 col-xxl-12">
                    <div className="card card-custom gutter-b">
                        <div className="card-header">
                            <div className="card-title">
                                <h3 className="card-label font-weight-bolder text-dark">
                                    {headerDialog && (
                                        <>
                                            {!headerDialog.title ? "Dettaglio POI " : headerDialog.title}
                                        </>
                                    )}
                                </h3>
                            </div>
                            <div className="card-toolbar">
                                {headerDialog && headerDialog.actions && (
                                    <>
                                        {headerDialog.actions.map((singleAction, index) => (
                                            <Button key={index}
                                                    className={singleAction.className}
                                                    variant={singleAction.variant ? singleAction.variant : 'primary'}
                                                    onClick={() => handleHeaderDialogClick(singleAction.action)}><i
                                                className={`fas fa-${singleAction.icon}`}/> {singleAction.label}
                                            </Button>

                                        ))}
                                    </>
                                )}

                            </div>
                        </div>
                        <div className="card-body">
                            <Formik
                                enableReinitialize={true}
                                initialValues={props.poi}
                                validationSchema={GeneralSchema}
                                onSubmit={(values, {setSubmitting}) => {
                                    setTimeout(() => {
                                         handleSubmit(values);
                                        setSubmitting(true);
                                    }, 400);
                                }}>
                                {({
                                      values,
                                      errors,
                                      touched,
                                      handleChange,
                                      handleBlur,
                                      handleSubmit,
                                      isSubmitting,
                                  }) => (

                                    <Form onSubmit={handleSubmit}>
                                        <PoiDetail data={props.poi} onSave={handleSubmit} onCancel={handleCancel}/>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}


const mapStateToProps = ({poi}) => {
    return {
        poi: poi.poi
    };
};


export default injectIntl(connect(mapStateToProps, poi.actions)(PoiDetailPage));