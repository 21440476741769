import React, { createContext, useState, useContext } from "react";

const HeaderDialogContext = createContext();
export default HeaderDialogContext;


export const handleHeaderDialogClick = (action) => {
    const name = Object.keys(action)[0];
    action[name]();

}

export function useHeaderDialog() {
  return useContext(HeaderDialogContext);
}

export const HeaderDialogConsumer = HeaderDialogContext.Consumer;

export function MetronicHeaderDialogProvider({ children }) {
  const [title, setTitle] = useState("");
  const [subTitle, setSubTitle] = useState("");
  const [show, setShow] = useState(null);
  const [actions, setActions] = useState(null);
  const value = { title, setTitle, subTitle, setSubTitle, actions, setActions, show, setShow };
  return (
    <HeaderDialogContext.Provider value={value}>
      {children}
    </HeaderDialogContext.Provider>
  );
}
