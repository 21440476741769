import axios from "axios";

import {actionTypes} from "../constants/action-types";

import {call, put, takeLatest} from "redux-saga/effects";
import {v4 as uuidv4} from "uuid";

export function* saga() {
    yield takeLatest(actionTypes.Login, loginRequestSaga);
    /*
    yield takeLatest(actionTypes.Login, function* loginSaga() {
        yield put(actions.requestUser());
    });

    yield takeLatest(actionTypes.Register, function* registerSaga() {
        yield put(actions.requestUser());
    });

    yield takeLatest(actionTypes.UserRequested, function* userRequested() {
        const { data: user } = yield getUserByToken();

        yield put(actions.fulfillUser(user));
    });*/
}

function* loginRequestSaga(action) {
    try {

        const result = yield call(login, action.payload);
        yield put({type: actionTypes.UserLoaded, payload: result.data});
    } catch (error) {
        yield put({type: actionTypes.LoginError, payload: "AUTH.VALIDATION.INVALID_LOGIN"});
    }


}

const login = async (action) => {


    const uuid = uuidv4();

    const headers = { "X-XSRF-TOKEN": uuid }

    return axios.post(process.env.REACT_APP_SERVICE_LOGIN,
            action,
        {
            headers: headers,
            withCredentials: true,
            xsrfCookieName: 'XSRF-TOKEN',
            xsrfHeaderName: 'X-XSRF-TOKEN'
        });

}