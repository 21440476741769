import React from "react";
import {Brand} from "../../_metronic/layout/components/brand/Brand";
import {KTUtil} from "../../_metronic/_assets/js/components/util";
import {toAbsoluteUrl} from "../../_metronic/_helpers";

export function ItemNotFoundPage() {

  return <>

    <div className="row">
      <div className="col-lg-12 col-xxl-12 text-center">
        <img
            alt="logo"
            src={toAbsoluteUrl("/media/logos/logo-SmartTour-2.png")}
            style={{ height: '250px' }}
        />
      </div>
    </div>
    <br />
    <div className="row">
      <div className="col-lg-12 col-xxl-12 text-center" style={{ fontSize:'30pt' }}>
        Ops! Request not found!
      </div>
    </div>

  </>;
}
