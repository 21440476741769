import React from "react";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../_helpers/index";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {NavLink} from "react-router-dom";
import KTLayoutAsideToggle from "../../../_assets/js/layout/base/aside-toggle";

export function AsideItem(props) {
    return (


            <OverlayTrigger
                placement="right"
                overlay={
                    <Tooltip id={props.title.toLowerCase().replace(/\s+/g, '-')}>{props.title}</Tooltip>
                }
            >
            {props.submenu ? (
                    <a href="#"
                        className={`nav-link btn btn-icon btn-clean btn-lg `}
                        data-toggle="tab"
                        data-target={`#${props.id}`}
                        role="tab"
                        onClick={() =>  {props.handleTabChange(props.id); KTLayoutAsideToggle.getToggle().toggle(); }}
                    >
                        <span className="svg-icon svg-icon-lg">
                          <SVG src={toAbsoluteUrl(props.icon)}/>
                        </span>
                    </a>
                ) : (
                    <NavLink
                        className={`nav-link btn btn-icon btn-clean btn-lg`}
                        to={props.url}
                        onClick={() => props.handleTabChange(props.id)}>
                          <span className="menu-text d-none">{props.title}</span>
                          <span className="svg-icon svg-icon-lg">
                            <SVG
                                src={toAbsoluteUrl(props.icon)}
                            />
                          </span>
                    </NavLink>
                )}
            </OverlayTrigger>



    )
}

