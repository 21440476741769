import React, {useCallback, useEffect, useState} from "react";
import {Button, Col, Form} from "react-bootstrap";
import {useFormik} from "formik";
import * as taxonomy from "../../../redux/taxonomy/taxonomy.action";
import {connect} from "react-redux";
import {useHeaderDialog} from "../../../_metronic/layout/_core/MetronicHeaderDialog";
import BootstrapTable from "react-bootstrap-table-next";
import MediaDialog from "../common/modal/MediaDialog";
import StageDialog from "../common/modal/StageDialog";
import {v4 as uuidv4} from "uuid";
import moment from "moment";
import {APP_LOCAL_DATETIME_FORMAT} from "../poi/ACDetail";
import * as Yup from "yup";
import {injectIntl} from "react-intl";

const Path = (props) => {

    const {intl} = props;

    const {poisSelected, path} = props.value;

    const headerDialog = useHeaderDialog();
    const [mediaValue, setMediaValue] = useState(null);
    const [show, setShow] = useState(false);
    const [showStage, setShowStage] = useState(false);
    const [contents, setContents] = useState([]);

    const PathSchema = Yup.object().shape({
        title: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(intl.formatMessage({id: "GENERIC.VALIDATION.REQUIRED_FIELD",}))
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {...path},
        //validationSchema: PathSchema,
        onSubmit: values => {
            props.onSave(values)
        }
    });

    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";
    };

    const normalize = (field, val = '') => {
        return {...formik.getFieldProps(field), value: formik.values[field] || val}
    }

    const handleNewMain = () => {
        setShowStage(true);
    }

    const handleDeleteClick = () => {

    }

    const handleMainDetail = useCallback((e, value) => {
        const index = formik.values.main.findIndex(m => m.identifier === value.identifier);
        setMediaValue({data: value, index: index});

        setShow(true);
    });

    const handleMainDelete = (e, value) => {
        formik.setFieldValue(`main`, formik.values.main.filter(m => m.identifier !== value.identifier));

    }

    const handleStageDelete = (e, value) => {
        formik.setFieldValue('stages', formik.values.stages.filter(m => m.content_id !== value.content_id));
       // setOperation(operation + 1)

    }

    const handleMainNew = () => {
        let identifier = uuidv4()
        let objectGroupId = identifier.substring(0, 4)
        let date = moment(new Date()).format(APP_LOCAL_DATETIME_FORMAT)
        setShow(true);
        setMediaValue({data: {title: '', mime_type: '', url: '', identifier: identifier, objectGroupId: objectGroupId, created_date: date, last_date: date}});
    }


    const handleSaveMedia = (values) => {
        const mediaList = formik.values.main;
        // const index = mediaValue.index >=0
        if (mediaValue.index >= 0) {
            mediaList[mediaValue.index] = {...values}
        } else {
            mediaList.push({...values})
        }


        setShow(false);
        formik.setFieldValue(`main`, [...mediaList])

        setMediaValue(null);

    }

    const handleCloseMedia = () => {
        setShow(false);
        setMediaValue(null);
    }


    const columns = [
        {
            dataField: 'title',
            text: 'Title'
        }, {
            dataField: 'mime_type',
            text: 'Mime Type'
        },
        {
            dataField: 'df1',
            isDummyField: true,
            text: 'Action',
            classes: 'text-right',
            headerFormatter: (column, colIndex) => {
                return (
                    <Button className="float-right"
                            size="sm"
                            variant="outline-light"
                            onClick={() => handleMainNew()}>
                        <i className="fas fa-plus-square"/> Nuovo Main
                    </Button>
                )
            },
            formatter: (cellContent, row) => {
                return (
                    <>
                        <Button variant="link" size="sm"
                                onClick={(e) => handleMainDetail(e, row)}>
                            <i className="fas fa-eye"></i>
                        </Button>
                        <Button variant="link" size="sm"
                                onClick={(e) => handleMainDelete(e, row)}>
                            <i className="fas fa-trash"></i>
                        </Button>
                    </>
                )
            }
        }

    ];
    const columnStages = [

        {
            dataField: 'type',
            text: 'Type'
        },
        {
            dataField: 'title',
            text: 'Title'
        },
        {
            dataField: 'df1',
            isDummyField: true,
            text: 'Action',
            classes: 'text-right',
            headerFormatter: (column, colIndex) => {
                return (
                    <Button className="float-right"
                            size="sm"
                            variant="outline-light"
                            onClick={() => handleNewMain()}>
                        <i className="fas fa-plus-square"/> Seleziona Stages
                    </Button>
                )
            },
            formatter: (cellContent, row) => {
                return (
                    <>
                        <Button className="float-right" variant="link" size="sm"
                                onClick={(e) => handleStageDelete(e, row)}>
                            <i className="fas fa-trash"></i>
                        </Button>
                    </>
                )
            }
        }
    ];



    const handleBack = () => {
        headerDialog.setShow(false);
        headerDialog.setTitle(null);
        headerDialog.setActions(null);
        props.onCancel();
    }

    const handleSave = () => {
        headerDialog.setShow(false);
        headerDialog.setTitle(null);
        headerDialog.setActions(null);
        formik.handleSubmit()
    }

    const handleClose = () => {
        setShowStage(false);

    }

    const handleSubmit = (values) => {

        setShowStage(false);
        formik.setFieldValue('stages', values);
    }

    useEffect(() => {

        props.taxonomyList('agetarget');
        props.taxonomyList('interests');
        props.taxonomyList('styles');

        headerDialog.setTitle(props.value && props.value.title != '' ? props.value.title : 'Nuovo Path');
        const actions = [
            {action: {handleBack}, icon: 'arrow-left', label: 'Indietro', variant: 'secondary', className: 'mr-2'},
            {action: {handleSave}, icon: 'save', label: 'Salva Path'},
        ]
        headerDialog.setActions(actions)

    }, []);

    useEffect(() => {

        const list = poisSelected ? props.pois.items.filter(item => poisSelected.some(p => p.identifier === item.identifier)).map(p => p.contents).flat() : [];
        //formik.setFieldValue('stages', formik.values.stages.map(s => ({...s, ...list.find(l => s.content_id === l.identifier)})))
        setContents(list)
    }, [props.pois, poisSelected])




    return (

        <>
            <Form.Group>
                <Form.Label>Title</Form.Label>
                <Form.Control type="text"
                              placeholder="Title"
                              {...formik.getFieldProps("title")}
                              className={`${getInputClasses("title")}`}/>
                {formik.touched.title && formik.errors.title ? (
                    <div className="fv-plugins-message-container">
                        <div className="fv-help-block text-danger">{formik.errors.title}</div>
                    </div>
                ) : null}

            </Form.Group>
            <Form.Group controlId="formDescription">
                <Form.Label>Description</Form.Label>
                <Form.Control as="textarea" rows={3} {...formik.getFieldProps("description")}  />
            </Form.Group>
            <Form.Row>
                <Form.Group as={Col} controlId="formAgeTarget">
                    <Form.Label>Age target</Form.Label>
                    <Form.Control as="select" {...normalize("age_target")}>
                        <option key="0" value={''}>Seleziona un valore</option>
                        {props.agetargets && (
                            props.agetargets.items.map((t) => (
                                <option key={t.key}>{t.value}</option>
                            ))
                        )}

                    </Form.Control>


                </Form.Group>
                <Form.Group as={Col} controlId="formInterestTarget">
                    <Form.Label>Tag Interest</Form.Label>
                    <Form.Control as="select" {...normalize("tag_interest")}>
                        <option key="0" value={''}>Seleziona un valore</option>
                        {props.interests && (
                            props.interests.items.map((t) => (
                                <option key={t.key}>{t.value}</option>
                            ))
                        )}

                    </Form.Control>

                </Form.Group>
                <Form.Group as={Col} controlId="formStyleTarget">
                    <Form.Label>Tag Style</Form.Label>
                    <Form.Control as="select" {...normalize("tag_style")}>
                        <option key="0" value={''}>Seleziona un valore</option>
                        {props.styles && (
                            props.styles.items.map((t) => (
                                <option key={t.key}>{t.value}</option>
                            ))
                        )}

                    </Form.Control>

                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Form.Group as={Col} controlId="formDuration">
                    <Form.Label>Duration</Form.Label>
                    <Form.Control type="text"
                                  placeholder="Duration"
                                  {...normalize("duration")}
                    />
                </Form.Group>
                <Form.Group as={Col} controlId="formColor">
                    <Form.Label>Color</Form.Label>
                    <Form.Control type="text"
                                  placeholder="Color"
                                  {...normalize("color")}
                    />
                </Form.Group>
            </Form.Row>
            <h5 className="mt-5">Main</h5>
            <div className="separator separator-dashed my-2 border-info"></div>
            <BootstrapTable
                keyField='identifier'
                data={formik.values.main ? formik.values.main : []}
                columns={columns}
                bootstrap4
                striped
                hover={true}
                bordered={false}

            />
            <h5 className="mt-5">Stages</h5>
            <div className="separator separator-dashed my-2 border-info"></div>
            <BootstrapTable
                keyField='content_id'
                data={formik.values.stages ? formik.values.stages : []}
                columns={columnStages}
                bootstrap4
                striped
                hover={true}
                bordered={false}

            />
            {mediaValue && (
                <MediaDialog value={mediaValue} show={show} parent={{...props.value}} handleSave={handleSaveMedia}
                             handleClose={handleCloseMedia}/>
            )}

            <StageDialog value={contents} selected={formik.values.stages ? formik.values.stages : []} show={showStage} onClose={handleClose} onSubmit={handleSubmit}/>


        </>
    )

}

const mapStateToProps = ({taxonomy, poi}) => {
    return {
        agetargets: taxonomy.agetargetList,
        styles: taxonomy.stylesList,
        interests: taxonomy.interestsList,
        pois: poi.poisList

    };
}


export default injectIntl(connect(mapStateToProps, taxonomy.actions)(Path));