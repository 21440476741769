import React, {useEffect, useState} from "react";
import {Button, Form, Modal} from "react-bootstrap";
import {useFormik} from "formik";
//import {Input} from "react-select";
import connect from "react-redux/es/connect/connect";
import * as poi from "../../../../redux/poi/poi.action";
import * as Yup from "yup";
import {injectIntl} from "react-intl";

const MediaDialog = (props) => {
    const {intl} = props;

    const [fileUpload, setFileUpload] = useState({isValid: false, size: null, name: null});
    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";
    };
    const MediaSchema = Yup.object().shape({
        title: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
    });
    const formik = useFormik({
        initialValues: {...props.value.data},
        validationSchema: MediaSchema,
        enableReinitialize: true,
        onSubmit: values => {
            // props.handleSave(values)
            console.log(values)
            let data = new FormData();
            data.append("site", process.env.REACT_APP_SITE_NAME);
            data.append("path", process.env.REACT_APP_CONSTANT_IMAGE_BASE_PATH);
            data.append("file", values.file);


            props.poiUploadAndSaveMedia(data);
            props.handleSave(values);

        }
    });

    const upload = (event) => {
        const upl = event.target.files[0];
        console.log('Upload event')
        if (upl) {
            setFileUpload({isValid: true, size: upl.size, name: upl.name});
            formik.setFieldValue('mime_type', upl.type)
            formik.setFieldValue('url', `${process.env.REACT_APP_CONSTANT_IMAGE_BASE_PATH}${upl.name}`)
            formik.setFieldValue('baseUrl', process.env.REACT_APP_CONSTANT_IMAGE_BASE_URL)
            formik.setFieldValue('size', upl.size)
            console.log('Eccomi', upl)
            formik.setFieldValue("file",upl);
            formik.setFieldValue("new",true);

        }

    }

    useEffect(() => {
        formik.setFieldValue("new",false);

    }, []);

    useEffect(() => {
        console.log('props.value.data', props.value.data)
    }, [props.value.data]);

    return (
        <>
            {props.value && (
                <Modal show={props.show}>
                    <Modal.Header closeButton>
                        <Modal.Title>{formik.values.title}</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        {formik.status ? (
                            <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                                <div className="alert-text font-weight-bold">{formik.status}</div>
                            </div>
                        ) : (
                            <></>
                        )}
                        <Form.Group controlId="formMediaTitle" className="fv-plugins-icon-container">
                            <Form.Label>Title</Form.Label>
                            <Form.Control type="text"
                                          name="title"
                                          placeholder="Title" {...formik.getFieldProps(`title`)}
                                          className={`${getInputClasses("title")}`}/>
                            {formik.touched.title && formik.errors.title ? (
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block text-danger">{formik.errors.title}</div>
                                </div>
                            ) : null}
                        </Form.Group>
                        <Form.Group controlId="formMediaUrl">
                            <Form.Label>File</Form.Label>
                            <Form.File custom>
                                <Form.File.Input isValid={fileUpload.isValid} onChange={(event) => upload(event)}/>
                                <Form.File.Label data-browse="Carica">
                                    Carica file input
                                </Form.File.Label>
                                <Form.Control.Feedback
                                    type="valid">{`${fileUpload.name} caricato con successo (${fileUpload.size} byte)`}</Form.Control.Feedback>
                            </Form.File>

                        </Form.Group>
                        <Form.Group controlId="formMediaUrl">
                            <Form.Label>URL</Form.Label>
                            <Form.Control type="text" readOnly
                                          placeholder="URL" {...formik.getFieldProps(`url`)} />
                        </Form.Group>
                        {formik.values.url && formik.values.mime_type && formik.values.mime_type.startsWith('image')  && !formik.values.new && (
                            <>
                            <h5>Anteprima</h5>
                            <img src={formik.values.url} className="pb-5 w-50"/>
                            </>
                        )}
                        <Form.Group controlId="formMediaMime">
                            <Form.Label>Mime Type</Form.Label>
                            <Form.Control type="text" readOnly
                                          placeholder="Mime Type" {...formik.getFieldProps(`mime_type`)} />
                        </Form.Group>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={props.handleClose}>Close</Button>
                        <Button variant="primary" onClick={formik.handleSubmit}>Save</Button>
                    </Modal.Footer>
                </Modal>
            )
            }
        </>
    )


}

export default injectIntl(connect(null, poi.actions)(MediaDialog));