import React, {useCallback, useEffect, useState} from "react";
import LMDetail from "./LMDetail";
import GenericTable from "../common/datadisplay/GenericTable";
import {useFormikContext} from "formik";
import {v4 as uuidv4} from "uuid";
import ConfirmDialog from "../common/ConfirmDialog";
import moment from "moment";
import {APP_LOCAL_DATETIME_FORMAT} from "./AC";


function LM(props) {
    const [lm, setLm] = useState(null);
    const [lmDelete, setLmDelete] = useState(null);
    const [modalConfirm, setModalConfirm] = useState(null);
    const [lmList, setLmList] = useState(null);
    const formik = useFormikContext();

    const handleDetailClick = useCallback((e, value, index) => {

        setLm({...value, index: index});
    })

    const handleNew = () => {
        let identifier = uuidv4()
        let objectGroupId = identifier.substring(0, 4)
        setLm({
            identifier: identifier,
            objectGroupId: objectGroupId,
            title: '',
            type: '',
            raggio: '',
            inclinazione_min: '',
            inclinazione_max: '',
            direzione_dx: '',
            direzione_sx: '',
            video_list: [],
            created_date: moment(new Date()).format(APP_LOCAL_DATETIME_FORMAT),
            last_date: moment(new Date()).format(APP_LOCAL_DATETIME_FORMAT)
        });
    }

    const handleSave = useCallback((values) => {
        const contentsLM = formik.values.contents.LM;
        if (lm.index >= 0) {
            contentsLM[lm.index] = {...values}

        } else {
            contentsLM.push({...values})
        }
        formik.setFieldValue(`contents.LM`, contentsLM)
        setLm(null);
        props.onBack();

    })

    const handleCancel = () => {
        setLm(null);
        props.onBack();
    }

    const handleDelete = useCallback((event, data) => {
        setModalConfirm(true)
        setLmDelete(data);
    })

    const toggleConfirm = () => {
        setModalConfirm(!modalConfirm);
    }

    const deleteItem = useCallback(() => {
        // this.props.onDelete(this.state.data.identifier);
        const newList = lmList.filter((item) => item.identifier !== lmDelete.identifier);
        setLmList(newList)
        formik.setFieldValue(`contents.LM`, newList)
        setModalConfirm(false);

    })

    useEffect(() => {

        if (formik.values.contents) {
            setLmList(formik.values.contents.LM);
        }
    }, [formik.values.contents])


    const header = ['title', 'type', 'raggio'];

    return (
        <>
            {!lm ?
                <>
                    <GenericTable headers={header} values={lmList} handleDetailClick={handleDetailClick}
                                  handleNew={handleNew} buttonTitleNew={"Nuovo LM"} buttonIconNew="fa-folder-plus"
                                  handleDeleteClick={handleDelete}
                    />
                    <ConfirmDialog modal={modalConfirm} onToggle={toggleConfirm}
                                   action={deleteItem}
                                   title="Cancella l'item LM" content="Sei sicuro di voler cancellare l'item LM?"/>
                </>
                :
                <LMDetail value={lm} handleSave={handleSave} handleBack={handleCancel}/>
            }
        </>
    )
}

export default LM;